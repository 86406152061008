import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";

import Revenue from "./Revenue";
import Section from "./Section";
import StoreVisits from "./StoreVisits";
import TopSellers from "./TopSellers";
import axios from "axios";
import { useSelector } from "react-redux";

const DashboardEcommerce = () => {
  document.title = "Dashboard | Velzon - React Admin & Dashboard Template";

  const [rightColumn, setRightColumn] = useState(false);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  const currentDate = new Date();
  const defaultStartDate = currentDate.toDateString();
  const defaultLastDate = currentDate.toDateString();
  const { user } = useSelector((state) => state.auth);
  // const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [dataInfoUser, setDataInfoUser] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const [startDate, setStarttDate] = useState();
  const [lastDate, setLastDate] = useState();
  const getDataSearchInfo = async (first, last) => {
    if (!first) {
      first = "";
    }
    if (!last) {
      last = "";
    }

    // console.log(
    //   `https://togethersms.com/MessageRecord/findAll-Finished-TotalCounts` +
    //     `&startDate=${first}&endDate=${last}`
    // );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/findAll-Finished-TotalCounts` +
          `?startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res, "res");

      if (res) {
        setDataInfo(res.campaignData);
        setDataInfoUser(res.aymentData);
        // setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearchInfo(startDate, lastDate);
  }, [startDate, lastDate]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section
                  rightClickBtn={toggleRightColumn}
                  setLastDate={setLastDate}
                  setStarttDate={setStarttDate}
                />
                <Row>
                  <Widget dataInfo={dataInfo} dataInfoUser={dataInfoUser} />
                </Row>
                <Row>
                  <Col xl={12}>
                    <Revenue />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
