import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Paginator } from "primereact/paginator";
import Details from "./Details";
export default function MessageFinishedTable() {
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [globalFilterUsername, setGlobalFilterUsername] = useState("");
  useEffect(() => {
    setProducts();
  }, []);

  const expandAll = () => {
    let _expandedRows = {};

    data.forEach((p) => (_expandedRows[`${p._id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const [searchText, setSearchText] = useState("");

  const allowExpansion = (rowData) => {
    return rowData.totalPhoneNumbers?.length > 0;
  };
  useEffect(() => {
    setProducts();
  }, [searchText]);
  const [t, i18n] = useTranslation();
  const rowExpansionTemplate = (data) => {
    // const [arrayToDownload, setarrayToDownload] = useState("");
    const arrayToDownload = data.totalPhoneNumbers;
    const downloadArrayAsFile = () => {
      const textToSave = arrayToDownload.join("\n"); // Convert array to a string

      const blob = new Blob([textToSave], { type: "text/plain" });
      saveAs(blob, "array.txt");
    };

    return (
      <div className="p-2">
        <div class="card col-12 ">
          <div class="card-body">
            <h5 className="text-primary">Information {data.name}</h5>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block">
                <label className="text-orange ">{t("Message :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>{data?.message}</span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Sender Name :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>{data?.senderName}</span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block ">
                <label className="text-orange ">{t("Send To :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                {data?.totalPhoneNumbers?.length > 0 ? (
                  <>
                    <div>
                      {data?.totalPhoneNumbers?.slice(0, 2).map((e) => (
                        <span>{e},</span>
                      ))}
                      <span
                        className="cursor-pointer"
                        onClick={() => downloadArrayAsFile()}
                      >
                        {" "}
                        {t("More Upload File")}
                      </span>
                    </div>
                  </>
                ) : (
                  <>notfound</>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-3 d-md-block d-none">
                <label className="text-orange ">
                  {t("Send Successfully to :")}
                </label>
              </div>
              <div class="form-floating  col-md-9 col-12">
                <span>
                  {data?.successPhoneNumbers?.length > 0 ? (
                    <>
                      {" "}
                      <div>
                        {data?.successPhoneNumbers.slice(0, 2).map((e) => (
                          <span>{e},</span>
                        ))}
                        {t("More Upload File")}
                      </div>
                    </>
                  ) : (
                    <>not found</>
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">
                  {t("Faild Sent To :")}
                  <div>
                    {data?.failedPhoneNumbers.slice(0, 2).map((e) => (
                      <span>{e},</span>
                    ))}
                    {/* {t("More Upload File")} */}
                  </div>{" "}
                </label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span></span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Message Cost :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>
                  {data?.totalPoints / data?.totalPhoneNumbers?.length}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Sent Cost :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>{data?.totalPoints}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportToExcel = () => {
    fetch(
      `https://togethersms.com/MessageRecord/export-all-finished?searchField=${globalFilter}&startDate=${startDate}&endDate=${lastDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Message Finish.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={expandAll}
      >
        {t("Expand All")}
      </div>
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={collapseAll}
      >
        {t("Collapse All")}
      </div>
    </div>
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const [startDate, setStarttDate] = useState("");
  const [lastDate, setLastDate] = useState("");

  const getDataSearch = async (page, value, first, last, userEmail) => {
    setLoading(true);
    if (!value) {
      value = "";
    }
    if (!first) {
      first = "";
    }
    if (!last) {
      last = "";
    }
    if (!userEmail) {
      userEmail = "";
    }
    // console.log(
    //   `https://togethersms.com/MessageRecord/findAll-Finished-Campaigns` +
    //     "?page=" +
    //     page +
    //     "searchField=" +
    //     value +
    //     "&userEmail=" +
    //     userEmail +
    //     `&startDate=${first}&endDate=${last}`
    // );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/findAll-Finished-Campaigns` +
          "?page=" +
          page +
          "&searchField=" +
          value +
          "&userEmail=" +
          userEmail +
          `&startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res);
      setLoading(false);

      if (res) {
        setData(res.campaigns);
        // setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  const getDataSearchInfo = async (page, value, first, last, userEmail) => {
    setLoading(true);
    if (!value) {
      value = "";
    }
    if (!first) {
      first = "";
    }
    if (!last) {
      last = "";
    }
    if (!userEmail) {
      userEmail = "";
    }
    console.log(
      `https://togethersms.com/MessageRecord/findAll-Finished-TotalCampaigns` +
        "?page=" +
        page +
        "searchField=" +
        value +
        "&userEmail=" +
        userEmail +
        `&startDate=${first}&endDate=${last}`
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/findAll-Finished-TotalCounts` +
          "?searchField=" +
          value +
          "&userEmail=" +
          userEmail +
          `&startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res);
      setLoading(false);

      if (res) {
        setDataInfo(res.campaignData);
        // setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(
      currentPage,
      globalFilter,
      startDate,
      lastDate,
      globalFilterUsername
    );
    getDataSearchInfo(
      currentPage,
      globalFilter,
      startDate,
      lastDate,
      globalFilterUsername
    );
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = async (selectedDates) => {
    setSelectedDate(selectedDates[0]);

    setStarttDate(selectedDates[0]);
    setLastDate(selectedDates[1]);

    const formattedDate = selectedDates[0].toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    console.log(formattedDate);
  };

  const [typeSelect, setTypeSelect] = useState();

  return (
    <>
      <Details data={dataInfo} />
      <div className="card">
        <Toast ref={toast} />
        <div className="p-2">
          <div className="d-flex align-items-center gap-2 border-0 ">
            <Flatpickr
              className="form-control  border-0 dash-filter-picker shadow "
              onChange={handleDateChange}
              options={{
                mode: "range",
                dateFormat: "d M, Y",
                defaultDate: [new Date()],
              }}
            />
            <div
              className="cursor-pointer input-group-text bg-primary border-primary text-white"
              onClick={() => {
                getDataSearch(
                  1,
                  globalFilter,
                  startDate,
                  lastDate,
                  globalFilterUsername
                );
                getDataSearchInfo(
                  1,
                  globalFilter,
                  startDate,
                  lastDate,
                  globalFilterUsername
                );
              }}
            >
              Search
            </div>
            <div
              className="cursor-pointer input-group-text bg-primary border-primary text-white"
              onClick={() => {
                setStarttDate("");
                setLastDate("");
                getDataSearch(1, globalFilter, "", "", globalFilterUsername);
                getDataSearchInfo(
                  1,
                  globalFilter,
                  "",
                  "",
                  globalFilterUsername
                );
              }}
            >
              Clear
              {/* <BsFillCalendar2MonthFill /> */}
            </div>
          </div>
          <div className="row">
            {typeSelect == "userName" ? (
              <div class="form-floating  col-md-6 col-12  mt-3">
                <input
                  id="textInputExample"
                  type="text"
                  class="form-control"
                  onInput={(e) => {
                    setGlobalFilterUsername(e.target.value);
                    setCurrentPage(1);

                    getDataSearch(1, "", startDate, lastDate, e.target.value);
                    getDataSearchInfo(
                      1,
                      "",
                      startDate,
                      lastDate,
                      e.target.value
                    );
                  }}
                  placeholder="Text Input"
                />
                <label for="textInputExample">{t("Search User name")}</label>
              </div>
            ) : typeSelect == "senderName" ? (
              <div class="form-floating  col-md-6 col-12  mt-3">
                <input
                  id="textInputExample"
                  type="text"
                  class="form-control"
                  onInput={(e) => {
                    setGlobalFilter(e.target.value);
                    setCurrentPage(1);

                    getDataSearch(1, e.target.value, startDate, lastDate, "");
                    getDataSearchInfo(
                      1,
                      e.target.value,
                      startDate,
                      lastDate,
                      ""
                    );
                  }}
                  placeholder="Text Input"
                />
                <label for="textInputExample">
                  {t("Search by sender names")}
                </label>
              </div>
            ) : (
              <div class="form-floating  col-md-6 col-12  mt-3">
                <input
                  id="textInputExample"
                  type="text"
                  class="form-control"
                  onInput={(e) => {
                    setGlobalFilter(e.target.value);
                    setCurrentPage(1);

                    getDataSearch(1, e.target.value, startDate, lastDate, "");
                    getDataSearchInfo(
                      1,
                      e.target.value,
                      startDate,
                      lastDate,
                      ""
                    );
                  }}
                  placeholder="Text Input"
                />
                <label for="textInputExample">{t("Search by message")}</label>
              </div>
            )}
            <div class="form-floating  col-md-6 col-12  mt-3">
              <div class="form-floating ">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  defaultValue={"1"}
                  onChange={(e) => setTypeSelect(e.target.value)}
                >
                  <option disabled value={"1"}>
                    {" "}
                    {t("Select type Search")}
                  </option>
                  <option value={"message"}>search by message</option>
                  <option value={"senderName"}>search by senderName</option>
                  <option value={"userName"}>search by userName</option>
                </select>

                {/* {submitted && !product.phone && (
                <small className="p-error">Message Price is required.</small>
              )} */}
              </div>
            </div>

            <div className="col-lg-6 col-12 d-flex flex-wrap gap-3 mt-3">
              <div
                className="btn btn-primary rounded-pill d-flex align-items-center mb-0 mb-2"
                // onClick={expandAll}
              >
                {t("Export the selected")}
              </div>
              <div
                className="btn btn-primary d-flex align-items-center rounded-pill mb-0 mb-2 "
                // onClick={collapseAll}
                onClick={exportToExcel}
              >
                {t("Export all the data")}
              </div>
            </div>
          </div>
        </div>

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          lazy
          loading={loading}
          width={"100%"}
          value={data}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="_id"
          header={header}
          rows={5}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
        >
          <Column selectionMode="multiple" headerStyle={{ width: ".5rem" }} />

          <Column
            expander={allowExpansion}
            style={{ width: "2rem" }}
            className="fs-14 "
          />
          <Column
            header="message"
            style={{ width: "8rem" }}
            body={(rowData) => {
              return <div>{rowData.message.slice(0, 10)}</div>;
            }}
            className="fs-14 "
          />
          <Column
            field="name"
            header="SMS no. :3666"
            sortable
            body={(rowData) => {
              return <div>{rowData.totalPhoneNumbers?.length}</div>;
            }}
            className="fs-13 "
          />
          <Column
            header="Drafts"
            sortable
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData.createdAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            className="fs-14 "
          />
          <Column
            body={(rowData) => {
              return <div>{rowData.totalPoints}</div>;
            }}
            header="Point"
            sortable
            className="fs-14 "
          />
        </DataTable>
        <Paginator
          first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
          rows={10} // Set the number of items per page
          totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
