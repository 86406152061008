import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { ContentState, EditorState, convertToRaw } from "draft-js";

import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";

const Tutorials = ({
  editRow,
  setProductDialog,
  setrefresh,
  refresh,
  
}) => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };
  // console.log(editRow);
  const api = editRow
    ? `Tutorial/update/${editRow?._id}`
    : "Tutorial/create-Tutorial";
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };
  const defaultValue = editRow?.tutorialDescription
    ? editRow?.tutorialDescription
    : "";

  document.title = "Listjs | Velzon - React Admin & Dashboard Template";
  console.log(editRow?.tutorialDescription);
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromText(defaultValue);
    return EditorState.createWithContent(contentState);
  });

  // دالة لتحديث حالة المحرر عند التغيير
  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    const contentState = ContentState.createFromText(defaultValue);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  }, [defaultValue]);
  const { user, token } = useSelector((state) => state.auth);

  // const onEditorStateChange = (newEditorState) => {
  //   setEditorState(newEditorState);
  //   const contentState = newEditorState.getCurrentContent(); // استخدم newEditorState بدلاً من editorState
  //   const rawText = contentState.getPlainText();
  //   settutorialDescription(rawText);
  //   console.log("قيمة النص:", rawText);
  //   // لاحظ أنه من الأفضل إضافة التعامل مع تفاصيل في حالة الحالة الجديدة هنا.
  // };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawText = contentState.getPlainText();
    console.log("قيمة النص:", rawText);
    settutorialDescription(rawText);
  };
  const toast = useRef();
  const [tutorialName, settutorialName] = useState(editRow?.tutorialName);
  const [tutorialDescription, settutorialDescription] = useState(
    editRow?.tutorialDescription
  );
  const [youtubeURL, setyoutubeURL] = useState(editRow?.youtubeURL);
  const [metaKeyword, setmetaKeyword] = useState(editRow?.metaKeyword);
  const [metaDescription, setmetaDescription] = useState(
    editRow?.metaDescription
  );
  const navigate = useNavigate();
  useEffect(() => {
    const contentState = ContentState.createFromText(defaultValue);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    settutorialDescription(contentState);
  }, [defaultValue]);
  const [tutorialLang, settutorialLang] = useState(editRow?.tutorialLang);
  const sendData = async (e) => {
    e.preventDefault();

    let data = {
      tutorialDescription: tutorialDescription,
      tutorialLang,
      youtubeURL,
      metaDescription,
      metaKeyword,
      tutorialName,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };

      const res = await axios.post(`https://togethersms.com/${api}`, data, config);
      console.log(res);
      navigate("/tutorials-table");
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        // getData();

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      // toast.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail: error.response.data.message,
      //   life: 3000,
      // });
      //  setError(true);
    }
  };
  const update = async (e) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const text = JSON.stringify(rawContentState);
    console.log(text);
    e.preventDefault();
    let data = {
      tutorialDescription: tutorialDescription,
      tutorialLang,
      youtubeURL,
      metaDescription,
      metaKeyword,
      tutorialName,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };

      const res = await axios.patch(
        `https://togethersms.com/${api}`,
        data,
        config
      );
      setProductDialog(false);
      setrefresh(!refresh);
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        // getData();

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setProductDialog(false);

      // toast.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail: error.response.data.message,
      //   life: 3000,
      // });
      //  setError(true);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Toast ref={toast} />
        <Container fluid>
          {!editRow && <BreadCrumb pageTitle="Tutorials" />}
          <Row>
            <Col lg={12}>
              <Card>
                {!editRow && (
                  <CardHeader>
                    <h4 className="card-title mb-0">Tutorials</h4>
                  </CardHeader>
                )}

                <CardBody>
                  <div className="listjs-table" id="customerList">
                    <form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        if (editRow) {
                          update(e);
                        } else {
                          sendData(e);
                        }
                      }}
                    >
                      <ModalBody>
                        <div className="mb-3" id="modal-id">
                          <label htmlFor="id-field" className="form-label">
                            Tutorial Name
                          </label>
                          <input
                            value={tutorialName}
                            type="text"
                            id="id-field"
                            className="form-control"
                            placeholder="Tutorial Name"
                            onChange={(e) => settutorialName(e.target.value)}
                          />
                        </div>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="اكتب نصا هنا ..."
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                        />
                        <div className="mb-3" id="modal-id">
                          <label htmlFor="id-field" className="form-label">
                            youtubeURL
                          </label>
                          <input
                            type="url"
                            id="id-field"
                            className="form-control"
                            placeholder="youtubeURL"
                            value={youtubeURL}
                            onChange={(e) => setyoutubeURL(e.target.value)}
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="customername-field"
                            className="form-label"
                          >
                            metaKeyword
                          </label>
                          <input
                            value={metaKeyword}
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="metaKeyword"
                            required
                            onChange={(e) => setmetaKeyword(e.target.value)}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="email-field" className="form-label">
                            metaDescription
                          </label>
                          <input
                            type="text"
                            value={metaDescription}
                            id="email-field"
                            className="form-control"
                            placeholder="metaDescription"
                            required
                            onChange={(e) => setmetaDescription(e.target.value)}
                          />
                        </div>

                        <div>
                          <label htmlFor="status-field" className="form-label">
                            tutorialLang
                          </label>
                          <select
                            value={tutorialLang}
                            className="form-control"
                            data-trigger
                            name="status-field"
                            id="tutorialLang"
                            required
                            onChange={(e) => settutorialLang(e.target.value)}
                          >
                            <option value="" selected>
                              choose languge
                            </option>
                            <option value="en">en</option>
                            <option value="ar">ar</option>
                          </select>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end my-4">
                          {editRow ? (
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="edit-btn"
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="add-btn"
                            >
                              Add Tutorial
                            </button>
                          )}
                        </div>
                      </ModalFooter>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
          }}
        >
          {" "}
          Add Customer{" "}
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Customer Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email-field" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email-field"
                className="form-control"
                placeholder="Enter Email"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone
              </label>
              <input
                type="text"
                id="phone-field"
                className="form-control"
                placeholder="Enter Phone no."
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="date-field" className="form-label">
                Joining Date
              </label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                }}
                placeholder="Select Date"
              />
            </div>

            <div>
              <label htmlFor="status-field" className="form-label">
                Status
              </label>
              <select
                className="form-control"
                data-trigger
                name="status-field"
                id="status-field"
                required
              >
                <option value="">Status</option>
                <option value="Active">Active</option>
                <option value="Block">Block</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Customer
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <ModalHeader
          toggle={() => {
            tog_delete();
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Tutorials;
