import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { ecomWidgets } from "../../common/data";

const Widgets = ({ dataInfo, dataInfoUser }) => {
  return (
    <React.Fragment>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Actually Sent Points
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.actuallySentPoints}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Failed Phone Numbers{" "}
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.failedPhoneNumbers}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Success Phone Numbers
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.successPhoneNumbers}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Campaigns{" "}
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.totalCampaigns}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Points
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.totalPoints}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  total Paid Points
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.totalPaidAmountByPoints}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl={3} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  total Paid  SenderName
                </p>
              </div>
              <div className="flex-shrink-0">
                {/* <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                  {item.badge ? (
                    <i className={"fs-13 align-middle " + item.badge}></i>
                  ) : null}{" "}
                  {item.percentage} %
                </h5> */}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      end={dataInfo.totalPaidAmountBySenderName}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="#" className="text-decoration-underline">
                  {/* {item.link} */}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-"}>
                  <i className={`text-secondary bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Widgets;
