import React from "react";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";

function Details({ data }) {
  return (
    <Row>
      <React.Fragment>
        {/* {data.map((item, key) => ( */}
        <Col lg={4} md={6}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ">
                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                    Actually Sent Points
                  </p>
                  <h4 className=" mb-0">
                    {" "}
                    <CountUp
                      start={0}
                      end={data.actuallySentPoints}
                      duration={1}
                    />
                  </h4>
                </div>
                <div className="flex-shrink-0 align-self-end">
                  {/* <span className={"badge badge-soft-" + item.badgeColor}>
                    <i className={"align-middle me-1 " + item.badge}></i>
                    {item.percentage} %<span></span>
                  </span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={6}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ">
                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                    Failed Phone Numbers
                  </p>
                  <h4 className=" mb-0">
                    {" "}
                    <CountUp
                      start={0}
                      end={data.failedPhoneNumbers}
                      duration={1}
                    />
                  </h4>
                </div>
                <div className="flex-shrink-0 align-self-end">
                  {/* <span className={"badge badge-soft-" + item.badgeColor}>
                    <i className={"align-middle me-1 " + item.badge}></i>
                    {item.percentage} %<span></span>
                  </span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={6}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ">
                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                    Success Phone Numbers
                  </p>
                  <h4 className=" mb-0">
                    {" "}
                    <CountUp
                      start={0}
                      end={data.successPhoneNumbers}
                      duration={1}
                    />
                  </h4>
                </div>
                <div className="flex-shrink-0 align-self-end">
                  {/* <span className={"badge badge-soft-" + item.badgeColor}>
                    <i className={"align-middle me-1 " + item.badge}></i>
                    {item.percentage} %<span></span>
                  </span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} md={6}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ">
                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                    Total Campaigns
                  </p>
                  <h4 className=" mb-0">
                    {" "}
                    <CountUp start={0} end={data.totalCampaigns} duration={1} />
                  </h4>
                </div>
                <div className="flex-shrink-0 align-self-end">
                  {/* <span className={"badge badge-soft-" + item.badgeColor}>
                    <i className={"align-middle me-1 " + item.badge}></i>
                    {item.percentage} %<span></span>
                  </span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4} md={6}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 ">
                  <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                    Total Points
                  </p>
                  <h4 className=" mb-0">
                    {" "}
                    <CountUp start={0} end={data.totalPoints} duration={1} />
                  </h4>
                </div>
                <div className="flex-shrink-0 align-self-end">
                  {/* <span className={"badge badge-soft-" + item.badgeColor}>
                    <i className={"align-middle me-1 " + item.badge}></i>
                    {item.percentage} %<span></span>
                  </span> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4} md={6}>
          <Card>
         
          </Card>
        </Col>
        {/* ))} */}
      </React.Fragment>
    </Row>
  );
}

export default Details;
