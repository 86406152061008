import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//import images
import logoLight from "../../assets/images/logo-light.png";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { loginSuccess, verifyPhone } from "../../slices/authSlice";

const CodeEmail = () => {
  const { user, tokenVirfy, admin } = useSelector((state) => state.auth);

  document.title =
    "Two Step Verification | Velzon - React Admin & Dashboard Template";
  const token = localStorage.getItem("tokenSms");

  const getInputElement = (index) => {
    return document.getElementById("digit" + index + "-input");
  };
  const dispatch = useDispatch();
  const [val1, setVal1] = useState();
  const [val2, setVal2] = useState();
  const [val3, setVal3] = useState();
  const [val4, setVal4] = useState();
  const [code, setCode] = useState("");
  const phone = localStorage.getItem("phone");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const page = window.location.pathname.split("/")[1];
  console.log(page);
  const moveToNext = (index) => {
    if (getInputElement(index).value.length === 1) {
      if (index !== 4) {
        getInputElement(index + 1).focus();
      } else {
        getInputElement(index).blur();
        // Submit code
        setCode(val1 + val2 + val3 + val4);
        handleSubmit();
        console.log(val1 + val2 + val3 + val4);
        console.log("submit code");
      }
    }
  };
  const navigate = useNavigate();

  const [count, setCount] = useState(180);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const handleSubmit = async () => {
    console.log(tokenVirfy, "tokenVirfy");
    setLoading(true);

    let data = {
      code,
    };
    // setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenVirfy}`,
          Token: tokenVirfy,
        },
      };
      const res = await axios.post(
        "https://togethersms.com/staff/confirm-email-verification/" +
          val1 +
          val2 +
          val3 +
          val4,
        config,
        config
      );
      if (res) {
        console.log("res", res);
        dispatch(verifyPhone());

        navigate("/dashboard");
        dispatch(loginSuccess(res));

        // setLoading(false);
        setLoading(false);
        setError(false);
      } else {
        console.log(res);
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setError(true);
      console.log(error);
      setLoading(false);
    }
  };
  const resetCode = async () => {
    setCount(180);

    // setLoading(true);

    // setLoading(true);
    // dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/staff/smsSignup` + admin.phoneNumber,
        config
      );
      console.log("res", res);

      if (res.statusCode == 200) {
        console.log("res", res);

        // setLoading(false);
        // setLoading(false);
      } else {
        console.log(res);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      // setLoading(false);
      //   setError(true);
    }
  };
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link
                        to="/dashboard"
                        className="d-inline-block auth-logo"
                      >
                        <img src={logoLight} alt="" height="20" />
                      </Link>
                    </div>
                    <p className="mt-3 fs-15 fw-medium">
                      Premium Admin & Dashboard Template
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="mb-4">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                            <i className="ri-mail-line"></i>
                          </div>
                        </div>
                      </div>

                      <div className="p-2 mt-4">
                        <div className="text-muted text-center mb-4 mx-lg-3">
                          <h4 className="">Verify Your Email</h4>
                          <p>
                            Please enter the 4 digit code
                            {/* sent to{" "}
                            <span className="fw-semibold">{phone}</span> */}
                          </p>
                        </div>

                        <form>
                          {error && (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {"Code is invaild"}
                            </Alert>
                          )}
                          <Row>
                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit1-input"
                                  className="visually-hidden"
                                >
                                  Digit 1
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit1-input"
                                  onKeyUp={() => moveToNext(1)}
                                  onChange={(e) => setVal1(e.target.value)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit2-input"
                                  className="visually-hidden"
                                >
                                  Digit 2
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit2-input"
                                  onKeyUp={() => moveToNext(2)}
                                  onChange={(e) => setVal2(e.target.value)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit3-input"
                                  className="visually-hidden"
                                >
                                  Digit 3
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit3-input"
                                  onKeyUp={() => moveToNext(3)}
                                  onChange={(e) => setVal3(e.target.value)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <label
                                  htmlFor="digit4-input"
                                  className="visually-hidden"
                                >
                                  Digit 4
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit4-input"
                                  onKeyUp={() => moveToNext(4)}
                                  onChange={(e) => setVal4(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </form>
                        {page === ""}
                        <div className="mt-3 pb-5">
                          <Button
                            color="secondary"
                            className="w-100"
                            disabled={loading ? true : false}
                            onClick={() => handleSubmit()}
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Confirm
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <p className="mt-2 text-center">
                    Time Remaining: {count} seconds
                  </p>
                  {count === 0 && (
                    <div className="mt-2 text-center">
                      <p className="mb-0">
                        Didn't receive a code ?{" "}
                        <div
                          onClick={() => resetCode()}
                          // to="/auth-pass-reset-basic"
                          className="fw-semibold text-primary text-decoration-underline cursor-pointer"
                        >
                          Resend
                        </div>{" "}
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default CodeEmail;
