import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardAnalytics from "../pages/DashboardAnalytics";
import DashboardCrm from "../pages/DashboardCrm";
import DashboardEcommerce from "../pages/DashboardEcommerce";
import DashboardJob from "../pages/DashboardJob/";
import DashboardCrypto from "../pages/DashboardCrypto";
import DashboardProject from "../pages/DashboardProject";
import DashboardNFT from "../pages/DashboardNFT";

// Widgets
// import Widgets from '../pages/Widgets/Index';

//Tables
import BasicTables from "../pages/Tables/BasicTables/BasicTables";
import GridTables from "../pages/Tables/GridTables/GridTables";
import ListTables from "../pages/Tables/ListTables/ListTables";
import DataTables from "../pages/Tables/DataTables/DataTables";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//APi Key

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// import FileManager from "../pages/FileManager";
// import ToDoList from "../pages/ToDo";
import VerifyEmail from "../pages/Authentication/VerifyEmail";
import Questions from "../pages/Authentication/Questions";
import CodeMobile from "../pages/Authentication/CodeMobile";
import SecretQuestion from "../pages/Authentication/SecretQuestion";
import CompleteData from "../pages/Authentication/CompleteData";
import Country from "../pages/Country/Country";
import EnterPhone from "../pages/Authentication/EnterPhone";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ForgetEnterPhone from "../pages/Authentication/ForgetEnterPhone";
import ResetCodeMobile from "../pages/Authentication/ResetCodeMobile";
import SmsGateway from "../pages/SmsGateway/SmsGateway";
import AddGateway from "../pages/SmsGateway/AddGateway";
import { useSelector } from "react-redux";
import Pricing from "../pages/Pricing/Pricing";
import Group from "../pages/Groups/Group";
import Contacts from "../pages/Contacts/Contacts";
import Users from "../pages/Users/User";
import Admin from "../pages/Admin/Admin";
import SenderName from "../pages/SenderName/SenderName";
import Settings from "../pages/Settings/Settings";
import RechargeArchive from "../pages/RechargeArchive/RechargeArchive";
import OneContact from "../pages/OneContact/OneContact";
import MessageArchive from "../pages/MessageArchive/MessageArchive";
import MessageFinished from "../pages/MessageFinished/MessageFinished";
import MessagePending from "../pages/MessagePending/MessagePending";
import SenderNamePrice from "../pages/SenderNamePrice/SenderNamePrice";
import PackagePrice from "../pages/PackagePrice/PackagePrice";
import Tutorials from "../pages/Tutorials/Tutorials";
import MainTutorials from "../pages/Tutorials/MainTutorials";
import BalacnceRechargeArchive from "../pages/BalacnceRechargeArchive/BalacnceRechargeArchive";
import Header from "../pages/Header/Header";
import Footer from "../pages/Footer/Footer";
import Meta from "../pages/Meta/Meta";
import EnterEmail from "../pages/Authentication/EnterEmail";
import CodeEmail from "../pages/Authentication/CodeEmail";
import ChooseTypeSend from "../pages/Authentication/ChooseTypeSend";

const authProtectedRoutes = [
  { path: "/dashboard-analytics", component: <DashboardAnalytics /> },
  { path: "/dashboard-crm", component: <DashboardCrm /> },
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  { path: "/dashboard-projects", component: <DashboardProject /> },
  { path: "/dashboard-nft", component: <DashboardNFT /> },
  { path: "/dashboard-job", component: <DashboardJob /> },
  { path: "/tutorials-table", component: <MainTutorials /> },
  { path: "/tutorials", component: <Tutorials /> },
  // { path: "/apps-calendar", component: <Calendar /> },

  // { path: "/apps-file-manager", component: <FileManager /> },
  // { path: "/apps-todo", component: <ToDoList /> },

  //Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-gridjs", component: <GridTables /> },
  { path: "/tables-listjs", component: <ListTables /> },
  { path: "/tables-datatables", component: <DataTables /> },
  { path: "/country", component: <Country /> },
  { path: "/sms-gateway", component: <SmsGateway /> },
  { path: "/add-gateway", component: <AddGateway /> },
  { path: "/pricing", component: <Pricing /> },
  { path: "/recharge-archive", component: <RechargeArchive /> },
  { path: "/groups", component: <Group /> },
  { path: "/contacts", component: <Contacts /> },
  { path: "/sender-name", component: <SenderName /> },
  { path: "/sender-name", component: <SenderName /> },
  { path: "/balance-sender-name", component: <BalacnceRechargeArchive /> },
  { path: "/sender-name-price", component: <SenderNamePrice /> },
  { path: "/package-price", component: <PackagePrice /> },
  { path: "/message-archive", component: <MessageArchive /> },
  { path: "/message-finished", component: <MessageFinished /> },
  { path: "/message-pending", component: <MessagePending /> },
  { path: "/admins", component: <Admin /> },
  { path: "/smspro-groups_grid/:id", component: <OneContact /> },
  { path: "/users", component: <Users /> },
  { path: "/header", component: <Header /> },
  { path: "/footer", component: <Footer /> },
  { path: "/meta", component: <Meta /> },
  { path: "/settings", component: <Settings /> },

  //User Profile

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/chooseTypeSend", component: <ChooseTypeSend /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
  // { path: "/verify-email", component: <VerifyEmail /> },
  // { path: "/verify-questions", component: <Questions /> },
  { path: "/code-mobile", component: <CodeMobile /> },
  { path: "/code-email", component: <CodeEmail /> },
  // { path: "/secret-question", component: <SecretQuestion /> },
  // { path: "/complete-data", component: <CompleteData /> },
  { path: "/enter-phone", component: <EnterPhone /> },
  { path: "/enter-email", component: <EnterEmail /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/enter-phone-reset-password", component: <ForgetEnterPhone /> },
  { path: "/reset-code-mobile", component: <ResetCodeMobile /> },
  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
