import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions

import logoLight from "../../assets/images/logo-light.png";
//Import config
import { facebook, google } from "../../config";

import withRouter from "../../Components/Common/withRouter";
import { useNavigate } from "react-router-dom/dist";
import {
  handleTokenVirfy,
  loginSuccess,
  loginUser,
} from "../../slices/authSlice";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const Login = (props) => {
  const { user, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user, "user");
    // if (error) navigate("/login");
    if (user) {
      navigate("/dashboard");
    }
  }, [navigate, user, error]);

  const [val, setval] = useState();
  const [answerOne, setAnswerOne] = useState();
  const [answerTwo, setAnswerTwo] = useState();
  //   const [error, serErrror] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const backendURL = "https://togethersms.com/";
  const [passwordShow, setPasswordShow] = useState(false);

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    // e.preventDefualt();
    let user = {
      email: email,
      password,
    };
    // dispatch(registerUser(user));
    console.log(user);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          recaptchatoken: val,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/staff/login`,
        user,
        config
      );
      // console.log(res.token, "res.token");
      dispatch(handleTokenVirfy(res.token));
      navigate("/chooseTypeSend");
      // navigate("/dashboard");
      // console.log(res);
      console.log("res", res);
      // if (res) console.log(res);
      setLoading(false);
      // dispatch(loginSuccess(res));
    } catch (error) {
      setLoading(false);
      console.log("sad", error);
      return error;
    }
  };

  document.title = "Basic SignIn | Velzon - React Admin & Dashboard Template";
  const onChange = (value) => {
    setval(value);
    console.log("Captcha value:", value);
  };
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to Velzon.
                      </p>
                    </div>

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          handleLogin(e);
                        }}
                      >
                        {error && (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {error}
                          </Alert>
                        )}
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link
                              to="/enter-phone-reset-password"
                              className="text-muted"
                            >
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              //   value={validation.values.password || ""}
                              type={passwordShow ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={(e) => setPassword(e.target.value)}
                            />

                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        {/* <ReCAPTCHA
                          sitekey="6LfbgvEpAAAAALJctzHECB1ELuFjnxxA1T1Tvrrf"
                          onChange={onChange}
                        /> */}
                        {/* <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div> */}

                        <div className="mt-4">
                          {/* {val ? ( */}
                          <Button
                            disabled={error ? null : loading ? true : false}
                            color="secondary"
                            className="w-100"
                            type="submit"
                          >
                            {error ? null : loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Sign In
                          </Button>
                          {/* ) : (
                            <Button
                              disabled={true}
                              color="secondary"
                              className="w-100"
                              type="submit"
                            >
                              Sign In
                            </Button>
                          )} */}
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                  {/* <Link to="/enter-phone-reset-password">Forget Password</Link> */}
                </Card>

                {/* <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account ?{" "}
                    <Link
                      to="/register"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Signup{" "}
                    </Link>{" "}
                  </p>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
