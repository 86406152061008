import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
  Accordion,
  AccordionItem,
  Collapse,
  Spinner,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action

//redux

import { Link, useNavigate } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import classnames from "classnames";
import axios from "axios";
import { adminVerfiy } from "../../slices/authSlice";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Register = () => {
  const [questionOne, setQuestionOne] = useState();
  const [questionTwo, setQuestionTwo] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFristName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [confirm_password, setConfirmPassword] = useState();
  const [answerOne, setAnswerOne] = useState();
  const [answerTwo, setAnswerTwo] = useState();
  const navigate = useNavigate("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);

  const validatePassword = () => {
    if (password === confirm_password) {
      setMessage(false);
      handleSubmit();
    } else {
      setMessage(true);
    }
  };
  document.title = "Admin-Profile";
  const handleSubmit = async () => {
    const countryCode = phone.substring(0, 2);
    setLoading(true);
    let data = {
      email,
      firstName,
      lastName,
      phoneNumber: phone.substring(1),
      password,
      countryCode,
      role: "Admin",
      confirmPassword: confirm_password,
      role: "Admin",
    };
    console.log(data);
    // setLoading(true);
    dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/staff/signup`,
        data,
        config
      );
      if (res) {
        dispatch(adminVerfiy(data));
        localStorage.setItem("tokenSms", res.token);
        console.log(res);

        console.log("res", res);
        navigate("/verify-email");
        localStorage.setItem("email", email);
        localStorage.setItem("phone", phone.substring(1));
        // setLoading(false);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="20" />
                    </Link>
                  </div>
                  {/* <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p> */}
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={12} lg={8} xl={8}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Admin Profile</h5>
                      {/* <p className="text-muted">Get your free velzon account now</p> */}
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validatePassword();
                        }}
                        className="needs-validation"
                      >
                        <div className="row">
                          {error && (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {"الايميل او رقم الهاتف موجودين بالفعل"}
                            </Alert>
                          )}
                          {message && (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {"Passwords do not match."}
                            </Alert>
                          )}
                          <div className="mb-3 col-12 col-lg-6">
                            <Label htmlFor="useremail" className="form-label">
                              Email <span className="text-danger">*</span>
                            </Label>
                            <Input
                              required
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email address"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="mb-3 col-12 col-lg-6">
                            <Label htmlFor="phone" className="form-label">
                              Phone <span className="text-danger">*</span>
                            </Label>
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                              placeholder="Enter phone "
                              className=" "
                            />
                          </div>
                          <div className="mb-3 col-12 col-lg-6">
                            <Label htmlFor="first_name" className="form-label">
                              Frist Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              required
                              name="first_name"
                              type="text"
                              placeholder="Enter Frist Name"
                              onChange={(e) => setFristName(e.target.value)}
                            />
                          </div>
                          <div className="mb-3 col-12 col-lg-6">
                            <Label htmlFor="last_name" className="form-label">
                              Last Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              required
                              name="last_name"
                              type="text"
                              placeholder="Enter Last Name"
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>

                          <div className="mb-3 col-12 col-lg-6">
                            <Label
                              className="form-label"
                              htmlFor="password-input"
                            >
                              Password
                            </Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <Input
                                name="password"
                                //   value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                className="form-control pe-5"
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}

                                //   onChange={validation.handleChange}
                                //   onBlur={validation.handleBlur}
                              />

                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                type="button"
                                id="password-addon"
                                onClick={() => setPasswordShow(!passwordShow)}
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                            </div>
                          </div>
                          <div className="mb-3 col-12 col-lg-6">
                            <Label
                              htmlFor="confirmPassword"
                              className="form-label"
                            >
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              required
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            disabled={loading ? true : false}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Save
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Signin{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
