import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { ProductService } from "./ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";

// import * as XLSX from "xlsx";
import { Link, useParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { Dropdown } from "reactstrap";
import axios from "axios";
import { useSelector } from "react-redux";
// import { Dropdown } from "react-bootstrap";
// import { AiOutlineDelete } from "react-icons/ai";

export default function CountryTable() {
  let emptyProduct = {
    phone: "",
    name: "",
    CONTACTS: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [data, setData] = useState();
  const [datagetway, setDatagetway] = useState();
  const [smsGetWay, setSmsGetWay] = useState();
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [messagePrice, setMessagePrice] = useState("");
  const [name, setName] = useState("");
  const [maxNumbersLink, setmaxNumbersLink] = useState("");
  const [pointsLink, setpointsLink] = useState("");
  const [englishLink, setenglishLink] = useState("");
  const [arabicLick, setarabicLick] = useState("");
  const [userPassword, setuserPassword] = useState("");
  const [userName, setuserName] = useState("");
  const [successResult, setsuccessResult] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [currencyName, setcurrencyName] = useState("");
  const [symbol, setsymbol] = useState("");
  const [isActive, setisActive] = useState("");
  const [countryName, setcountryName] = useState("");
  const [currency, setCurrency] = useState("");
  const [packageType, setPackageType] = useState("");
  const [display, setDisplay] = useState("");
  const [editRow, setEditRow] = useState("");

  const getData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/country`,

        config,
        config
      );
      console.log(res);
      if (res) {
        setData(res);

        // setTotalPages(res.data.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/smsGetWay`,

        config,
        config
      );
      if (res) {
        setDatagetway(res);

        // setTotalPages(res.data.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getData();
    getDataGetway();
  }, []);

  ///sendData
  const sendData = async (e) => {
    e.preventDefault();
    setName("");
    if (name != "") {
      hideDialog();
    }
    console.log(user.token);
    // setLoading(true);
    let data = {
      countryName,
      countryCode: countryCode,
      smsGetWay,
      currencyName,
      symbol,

      isActive: isActive.toLowerCase() === "true",
    };

    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/country/create`,
        data,
        config
      );
      console.log(res);
      hideDialog();
      if (res) {
        //  dispatch(adminVerfiy(data));
        getData();
        // setLoading(false);
        // setLoading(false);
        setName("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        hideDialog();
        console.log(res);
        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.message,
          life: 3000,
        });
        hideDialog();
      }
    } catch (error) {
      // setLoading(false);
      hideDialog();
      console.log(error);
      //   setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        // detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const handleEdit = async () => {
    hideDialog();
    // setLoading(true);
    console.log(isActive);
    let data = {
      countryName: countryName || editRow.countryName,
      countryCode: +countryCode || editRow.countryCode,
      smsGetWay: smsGetWay || editRow.smsGetWay,

      isActive: isActive
        ? isActive === "true"
          ? true
          : false
        : editRow.isActive,
    };

    console.log(data);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/country/update/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      setEditRow("");
      if (res) {
        getData();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        // setLoading(false);
      } else {
        console.log(res);
        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
      //   setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        // detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const handleDelete = async (deleteProductDialog) => {
    setDeleteProductDialog(false);
    hideDialog();
    setEditRow("");
    // setRowDelete("");
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/priceConfig/delete/${deleteProductDialog._id}`,
        config
      );
      console.log(res);
      if (res) {
        getData();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const openNew = () => {
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditRow("");
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      let _products = [...products];
      let _product = { ...product };

      if (product.id) {
        const index = findIndexById(product.id);

        _products[index] = _product;
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Updated",
          life: 3000,
        });
      } else {
        _product.id = createId();
        _product.image = "product-placeholder.svg";
        _products.push(_product);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Created",
          life: 3000,
        });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);

    setDeleteProductDialog(product);
  };

  const deleteProduct = () => {
    let _products = products.filter((val) => val.id !== product.id);

    setProducts(_products);
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };

    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };
  const parms = useParams();
  const exportToExcel = () => {
    const exportData = selectedProducts.map((product) => ({
      id: product?.id || "", // Use optional chaining and default value
      Name: product?.name || "",
      Price: product?.price || 0,
      Category: product?.category || "",
      Rating: product?.rating || 0,
      InventoryStatus: product?.inventoryStatus || "",
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Products");

    XLSX.writeFile(wb, "exported_products.xlsx");
  };
  const leftToolbarTemplate = () => {
    return (
      <div className="flex  flex-wrap fs-5 gap-3">
        <Button
          color="success"
          className="add-btn me-1 bg-gradient bg-success"
          onClick={openNew}
          id="create-btn"
        >
          <i className="ri-add-line align-bottom me-1"></i> Add Country
        </Button>
      </div>
    );
  };

  const actionEdit = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-gradient	 py-1 px-2"
            onClick={() => {
              setProductDialog(true);
              setEditRow(rowData);
            }}
          >
            <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };
  const actionDelete = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-danger py-1 px-2"
            onClick={() => confirmDeleteProduct(rowData)}
          >
            <i className="ri-delete-bin-2-line bg-danger"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case "INSTOCK":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return null;
    }
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
      <span className="p-input-icon-left col-12 col-md-6">
        <i className="pi pi-search" />
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search..."
            class="form-control"
          />
          <label for="textInputExample">Search...</label>
        </div>
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );
  const exportSelectedToExcel = () => {
    if (selectedProducts && selectedProducts.length > 0) {
      const selectedRows = products.filter((product) =>
        selectedProducts.includes(product.id)
      );

      const exportData = selectedRows.map((product) => ({
        Name: product.name,
        Description: product.description,
        Price: product.price,
        Category: product.category,
        Rating: product.rating,
        InventoryStatus: product.inventoryStatus,
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SelectedProducts");

      XLSX.writeFile(wb, "selected_products.xlsx");
    }
  };
  const nameBodyTemplate = (rowData) => {
    return <Link to={`/smspro-groups_grid/${rowData.id}`}>{rowData.name}</Link>;
  };
  const [checked, setChecked] = useState(false);

  const handleToggleChange = (rowData, value) => {
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData.id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <InputSwitch
        checked={checkedRows[rowData.id] || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});
  const handleExcelUpload = (event) => {
    const file = event.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Assuming first sheet

      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setProducts((prevProducts) => [...prevProducts, ...jsonData]);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Data Imported",
        life: 3000,
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const handleUploadExcel = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setUploadedExcelData(jsonData);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Excel Data Uploaded",
        life: 3000,
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const [t, i18n] = useTranslation();
  const [importExcel, setImportExcel] = useState(false);
  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <input
          id="upload-excel-input"
          type="file"
          accept=".xlsx, .xls"
          style={{ display: "none" }}
          onChange={handleUploadExcel}
        />

        <DataTable
          ref={dt}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}

          //   selectionMode={"checkbox"}
        >
          <Column
            className="fs-13"
            field="countryName"
            header="Country Name"
          ></Column>

          <Column
            className="fs-13"
            field="countryCode"
            header="Country Code"
          ></Column>
          <Column
            className="fs-13"
            field="isActive"
            header="Is Active"
          ></Column>
          <Column
            className="fs-13"
            field="usersCount"
            header="Users Count"
          ></Column>

          <Column
            className="fs-13"
            header="Edit"
            body={actionEdit}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            className="fs-13"
            header="Delete"
            body={actionDelete}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "50rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow._id ? t("Edit Country") : t("Create Country")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) => (editRow._id ? handleEdit() : sendData(e))}
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <div className="row">
          <div className="field col-12 col-xl-6">
            <label htmlFor="name1" className="font-bold">
              Country Name
            </label>
            <div class="form-floating mb-4">
              <input
                id="name"
                type="text"
                //   placeholder="Text Input"
                onChange={(e) => setcountryName(e.target.value)}
                required
                //   autoFocus
                class="form-control"
                defaultValue={editRow.countryName}
              />

              <label for="name">Country Name</label>
            </div>
          </div>
          <div className="field col-12 col-xl-6">
            <label htmlFor="description" className="font-bold">
              Country Code
            </label>
            <div class="form-floating mb-4">
              <input
                id="textInputExample"
                type="text"
                class="form-control"
                placeholder="Text Input"
                required
                defaultValue={editRow.countryCode}
                onChange={(e) => setcountryCode(e.target.value)}
              />

              <label for="textInputExample">Country Code</label>
            </div>
          </div>
          <div className="field col-12 col-xl-6">
            <label htmlFor="description" className="font-bold">
              Currency Name
            </label>
            <div class="form-floating mb-4">
              <input
                id="textInputExample"
                type="text"
                class="form-control"
                placeholder="Text Input"
                required
                defaultValue={editRow.currencyName}
                onChange={(e) => setcurrencyName(e.target.value)}
              />

              <label for="textInputExample">Currency Name</label>
            </div>
          </div>
          <div className="field col-12 col-xl-6">
            <label htmlFor="description" className="font-bold">
              Symbol
            </label>
            <div class="form-floating mb-4">
              <input
                id="textInputExample"
                type="text"
                class="form-control"
                placeholder="Text Input"
                required
                defaultValue={editRow.symbol}
                onChange={(e) => setsymbol(e.target.value)}
              />

              <label for="textInputExample"> Symbol</label>
            </div>
          </div>
          <div className="field col-12 col-xl-6">
            <label htmlFor="description" className="font-bold">
              Is Active
            </label>
            <div class="form-floating mb-4">
              <select
                class="form-select pt-1 "
                aria-label="Default select example"
                defaultValue={editRow.isActive}
                onChange={(e) => setisActive(e.target.value)}
              >
                <option selected>Is Active</option>
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            </div>
          </div>
          <div className="field col-12 col-xl-6">
            <label htmlFor="description" className="font-bold">
              Sms smsGetWay
            </label>
            <div class="form-floating mb-4 ">
              <select
                class="form-select pt-1 "
                aria-label="Default select example"
                defaultValue={editRow.isActive}
                onChange={(e) => setSmsGetWay(e.target.value)}
              >
                <option selected>Sms smsGetWay</option>
                {datagetway?.map((e) => (
                  <option value={e._id}>{e.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={importExcel}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Upload Excel")}
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={() => setImportExcel(false)}
      >
        <div className="d-flex align-items-center justify-content-between ">
          <div class="form-select-wrapper col-3 d-md-block d-none">
            <label className="text-orange ">{t("Upload Excel	")}</label>
          </div>
          <div>
            <button
              label="Upload Excel"
              icon="pi pi-upload"
              className="btn btn-primary rounded-xl mx-1 fs-14"
              onClick={() =>
                document.getElementById("upload-excel-input").click()
              }
            >
              {t("Upload")}
              <i class="uil uil-export  mx-2"></i>
            </button>
          </div>
          <input
            id="upload-excel-input"
            type="file"
            accept=".xlsx, .xls"
            style={{ display: "none" }}
            //   onChange={handleUploadExcel}
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete(deleteProductDialog)}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete{" "}
              <b>{deleteProductDialog.countryName}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
