import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
// import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";
import Tutorials from "./Tutorials";
export default function MainTutorialsTable() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [addNumberInGroup, setAddNumberInGroup] = useState(false);
  const [refresh, setrefresh] = useState(true);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user, token } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");
  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const [t, i18n] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();
  // const getData = async (page) => {
  //   setLoading(true);
  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         withCredentials: false,
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     };
  //     const res = await axios.get(
  //       `https://togethersms.com/Group/get-user-groups` + "?page=" + page,

  //       config
  //     );
  //     console.log(res);
  //     setLoading(false);
  //     if (res) {
  //       setData(res.groups);

  //       setTotalPages(res.total / 10);
  //     } else {
  //       console.log(res);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);

  //     //  setError(true);
  //   }
  // };
  const getDataSearch = async (page, value) => {
    console.log(token);
    setLoading(true);
    if (!value) {
      value = "";
    }
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/Tutorial` +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      setLoading(false);
      if (res) {
        setData(res.data);
        setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  ///sendData
  const sendData = async (e) => {
    e.preventDefault();
    setName("");
    if (name != "") {
      hideDialog();
    }
    setLoading(true);
    let data = {
      name,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/Group/create`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        // getData();
        getDataSearch(currentPage, globalFilter);
        setLoading(false);
        setLoading(false);
        setName("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage, refresh]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const handleEdit = async () => {
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(token);
    let data = {
      name,
    };
    console.log(editRow);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/Group/update-group/${editRow.group.user}/${editRow.group._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handleDelete = async () => {
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*/*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/Tutorial/delete/${deleteProductDialog?._id}`,
        config
        // Include the data as a separate property in the config object
      );

      console.log(res);
      if (res) {
        getDataSearch(currentPage);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const deleteMultipleHandle = async () => {
    hideDeleteProductsDialog();
    setSubmitted(true);
    console.log("user", user);
    console.log("user", token);

    const idArray = selectedProducts.map((item) => item.group._id);
    const data = {
      groupIds: idArray,
    };
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*/*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/Group/delete`,
        config
        // Include the data as a separate property in the config object
      );

      console.log(res);
      if (res) {
        getDataSearch(currentPage);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
    // try {
    //   const config = {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       withCredentials: false,
    //       Authorization: `Bearer ${user.token}`,
    //     },
    //   };
    //   const res = await axios.delete(
    //     `https://togethersms.com/Group/delete`,
    //     data,
    //     config
    //   );
    //   console.log(res);
    //   if (res) {
    //     setEditRow("");
    //     // getData();
    //     setDeleteProductDialog(false);
    //     toast.current.show({
    //       severity: "success",
    //       summary: "Successful",
    //       detail: "Deleted Successful",
    //       life: 3000,
    //     });
    //   } else {
    //     console.log(res);
    //     setEditRow("");
    //     setDeleteProductDialog(false);
    //   }
    // } catch (error) {
    //   // setLoading(false);
    //   console.log(error);
    //   setEditRow("");
    //   setDeleteProductDialog(false);

    //   //  setError(true);
    // }
  };

  const [exp, setExport] = useState("");

  // useEffect(() => {

  // }, [token, data]);

  ///////////

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditRow("");
    setSubmitted(false);
    setProductDialog(false);
  };
  const handleAddOneInGrroup = () => {
    setAddNumberInGroup(true);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setEditRow(product);
    setProductDialog(true);
  };
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const [rowDelete, setRowDelete] = useState("");
  const exportToExcel = async () => {
    const idArray = selectedProducts.map((item) => item.group._id);
    const array = {
      groupIds: idArray,
    };
    console.log(array);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        responseType: "blob",
      };
      const res = await axios.post(
        `https://togethersms.com/Group/export-selectedUser-group`,
        array,
        config
      );
      console.log(res);
      if (res) {
        //  dispatch(adminVerfiy(data));
        // getData();
        if (res) {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }

    fetch(`https://togethersms.com/Group/export-selected-group`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(array),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.log(response.json());
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const exportAll = () => {
    console.log(
      `https://togethersms.com/Group/export-all-groups?userPhoneNumber=${globalFilter}`
    );
    fetch(
      `https://togethersms.com/Group/export-all-groups?userPhoneNumber=${globalFilter}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          console.log(response);
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
        setExportAll(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap  fs-5 gap-3">
        {/* <button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
          className="btn btn-danger rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Delete")}
          <i class="uil uil-shopping-basket mx-2"></i>
        </button>
        <button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Create Group")}
          <i class="uil uil-plus mx-2"></i>
        </button> */}{" "}
        <Link to={"/tutorials"}>
          <button
            label="Export Selected to Excel"
            icon="pi pi-file-excel"
            className="btn btn-success rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Add Tutorials")}
            <i class="uil uil-import mx-2"></i>
          </button>
        </Link>
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
      <span className="p-input-icon-left col-12 col-md-6">
        <i className="pi pi-search" />
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="search"
            onInput={(e) => {
              setGlobalFilter(e.target.value);
              setCurrentPage(1);

              getDataSearch(1, e.target.value);
            }}
            placeholder="Search..."
            class="form-control"
          />
          <label for="textInputExample">{t("Search...")}</label>
        </div>
      </span>
    </div>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteMultipleHandle}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <Link to={`/smspro-groups_grid/${rowData.group._id}`}>
        {rowData.group.name}
      </Link>
    );
  };

  const updateStatus = async (e) => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    setLoading(true);

    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const status = await axios.patch(
        `https://togethersms.com/group/update-group-blacklist/${e.group._id}`,
        config,
        config
      );
      console.log(status);
      if (status) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${user.token}`,
            },
          };
          const res = await axios.get(
            `https://togethersms.com/Group/get-user-groups` +
              "?page=" +
              currentPage +
              "&searchField=" +
              globalFilter,

            config
          );
          console.log(status);
          setLoading(false);
          if (res) {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: status?.data?.message,
              life: 3000,
            });
            setLoading(false);
            setData(res.groups);
            setTotalPages(res.total / 10);
          } else {
            console.log(res);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);

          //  setError(true);
        }
      } else {
        console.log(status);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const handleToggleChange = (rowData, value) => {
    updateStatus(rowData);

    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData.group._id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <InputSwitch
        checked={rowData.group.blackList || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const confirmDeleteProduct = (product) => {
    setProduct(product);

    setDeleteProductDialog(product);
  };
  const actionEdit = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-gradient	 py-1 px-2"
            onClick={() => {
              setProductDialog(true);
              setEditRow(rowData);
            }}
          >
            <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };
  const actionDelete = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-danger py-1 px-2"
            onClick={() => confirmDeleteProduct(rowData)}
          >
            <i className="ri-delete-bin-2-line bg-danger"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          lazy
          loading={loading}
          width={"100%"}
          ref={dt}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          header={header}
          selectionMode={"checkbox"}
          totalRecords={20}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          {/* <Column
            className="fs-13 "
            field="id"
            header="Id"
            
            style={{ minWidth: "2rem" }}
          ></Column> */}

          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            field="tutorialName"
            header="tutorialName"
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-13"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    {rowData.tutorialDescription.slice(0, 20)}
                  </div>
                </React.Fragment>
              );
            }}
            header="tutorialDescription"
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-13"
            field="youtubeURL"
            header="youtubeURL"
            style={{ minWidth: "7rem" }}
          ></Column>
          <Column
            className="fs-13"
            field="metaDescription"
            header="metaDescription"
            style={{ minWidth: "7rem" }}
          ></Column>

          {/* <Column
            className="fs-13"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData?.group?.createdAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header={t("Created At")}
            
            style={{ minWidth: "2rem" }}
          ></Column> */}
          <Column
            className="fs-13"
            header="tutorialLang"
            field="tutorialLang"
            // body={statusBodyTemplate2}
            style={{ minWidth: "2rem" }}
          ></Column>

          <Column
            className="fs-13"
            header="Edit"
            body={actionEdit}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            className="fs-13"
            header="Delete"
            body={actionDelete}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />

      <Dialog
        visible={productDialog}
        style={{ width: "50rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Edit Group Tutorials")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            {/* <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) => handleEdit()}
            /> */}
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <Tutorials
          editRow={editRow}
          setProductDialog={setProductDialog}
          setrefresh={setrefresh}
          refresh={refresh}
          toast={toast}
        />
      </Dialog>
      {/* <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow.name ? t("Edit Group Name") : t("Group Name")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => (editRow.name ? handleEdit() : sendData())}
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            onChange={(e) => setName(e.target.value)}
            required
            //   autoFocus
            defaultValue={name}
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
      </Dialog> */}

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete ")}
              <b>{deleteProductDialog?.tutorialName}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete the selected products?")}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
