import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const backendURL = "https://mytinkle.net/";

export const loginUser = createAsyncThunk(
  "loginUser",
  async ({ user, setLoading, navigate }, thunkAPI) => {
    setLoading(true);
  }
);

export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (user, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${backendURL}/logout`, user, config);
      console.log(res);
      // navigator("/home");
    } catch (error) {
      // return custom error message from backend if present
      console.log(error);
    }
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    msg: false,
    signIn: false,
    user: JSON.parse(localStorage.getItem("userSmsDashboard"))
      ? JSON.parse(localStorage.getItem("userSmsDashboard"))
      : "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    tokenVirfy: localStorage.getItem("tokenVirfy")
      ? localStorage.getItem("tokenVirfy")
      : "",
    loading: false,
    error: false,
    verifyEmail: localStorage.getItem("verifyEmail")
      ? localStorage.getItem("verifyEmail")
      : false,
    vPhone: localStorage.getItem("vPhone")
      ? localStorage.getItem("vPhone")
      : false,
    questions: localStorage.getItem("questions")
      ? localStorage.getItem("questions")
      : false,
    data: false,
    admin: JSON.parse(localStorage.getItem("admin"))
      ? JSON.parse(localStorage.getItem("admin"))
      : [],

    // currentUser: null,
  },
  reducers: {
    loginGoogle: (state, actions) => {
      console.log(actions.payload);
      localStorage.setItem("userSmsDashboard", JSON.stringify(actions.payload));
      state.user = actions.payload;
    },
    loginStart: (state, actios) => {
      state.loading = true;
      console.log(actios);
    },
    handleTokenVirfy: (state, actios) => {
      console.log(actios, "actios");
      state.tokenVirfy = actios.payload;
      localStorage.setItem("tokenVirfy", actios.payload);

      // state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;

      localStorage.setItem("userSmsDashboard", JSON.stringify(action.payload));
    },
    verifyEmail: (state, action) => {
      state.verifyEmail = true;
      localStorage.setItem("verifyEmail", true);
    },
    verifyPhone: (state, action) => {
      state.vPhone = true;
      localStorage.setItem("vPhone", true);
    },
    verifyQuestions: (state, action) => {
      state.questions = true;
      localStorage.setItem("questions", true);
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    logout: (state, action) => {
      localStorage.removeItem("userSmsDashboard");
      localStorage.removeItem("CurrentStudy");
      localStorage.removeItem("token");
      state.user = "";
    },
    adminVerfiy: (state, action) => {
      console.log(action.payload);
      state.admin = action.payload;
      localStorage.setItem("admin", JSON.stringify(action.payload));
    },
    deleteSign: (state, action) => {
      state.signIn = false;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      if (payload !== "Request failed with status code 401") {
        state.msg = false;
        localStorage.setItem("userSmsDashboard", JSON.stringify(payload));
        // state.token = payload.token;
        // localStorage.setItem("token", payload.token);

        state.user = payload.data;
      } else {
        state.msg = true;
        state.error = "email is invalid";
      }

      state.success = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
      console.log(payload, "payload");
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  loginGoogle,
  verifyPhone,
  deleteSign,
  verifyEmail,
  verifyQuestions,
  adminVerfiy,
  handleTokenVirfy,
} = authSlice.actions;
export default authSlice.reducer;
