import React from "react";

function AddGateway() {
  return (
    <>
      <div className="field">
        <label htmlFor="name1" className="font-bold">
          Country Name
        </label>
        <div class="form-floating mb-4">
          <input
            id="name"
            type="text"
            //   placeholder="Text Input"
            onChange={(e) => onInputChange(e, "name")}
            required
            //   autoFocus
            value={product.name}
            class="form-control"
          />
          {submitted && !product.name && (
            <small className="p-error">Country Name is required.</small>
          )}
          <label for="name">Country Name</label>
        </div>
      </div>
      <div className="field">
        <label htmlFor="description" className="font-bold">
          Phone Prfefix
        </label>
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="text"
            class="form-control"
            placeholder="Text Input"
            value={product.CONTACTS}
            onChange={(e) => onInputChange(e, "CONTACTS")}
            required
          />
          {submitted && !product.phone && (
            <small className="p-error">Phone Prfefix is required.</small>
          )}
          <label for="textInputExample">Phone Prfefix</label>
        </div>
      </div>
      <div className="field">
        <label htmlFor="description" className="font-bold">
          Country Flag
        </label>
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="text"
            class="form-control"
            placeholder="Text Input"
            value={product.CONTACTS}
            onChange={(e) => onInputChange(e, "CONTACTS")}
            required
          />
          {submitted && !product.phone && (
            <small className="p-error">Country Flag is required.</small>
          )}
          <label for="textInputExample">Country Flag</label>
        </div>
      </div>
    </>
  );
}

export default AddGateway;
