import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Spinner } from "react-bootstrap";
import { Paginator } from "primereact/paginator";
export default function MessageArchiveTable() {
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  useEffect(() => {
    setProducts();
  }, []);

  const expandAll = () => {
    let _expandedRows = {};

    data.forEach((p) => (_expandedRows[`${p._id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const [searchText, setSearchText] = useState("");

  const allowExpansion = (rowData) => {
    return rowData.totalPhoneNumbers.length > 0;
  };
  useEffect(() => {
    setProducts();
  }, [searchText]);
  const [t, i18n] = useTranslation();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const rowExpansionTemplate = (data) => {
    // const [arrayToDownload, setarrayToDownload] = useState("");
    const arrayToDownload = data.totalPhoneNumbers;
    const downloadArrayAsFile = () => {
      const textToSave = arrayToDownload.join("\n"); // Convert array to a string

      const blob = new Blob([textToSave], { type: "text/plain" });
      saveAs(blob, "array.txt");
    };

    const sendConfirm = async (e) => {
      console.log(e);

      const data = { campaignId: e };
      setLoadingConfirm(true);

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${user.token}`,
          },
        };
        const res = await axios.post(
          `https://togethersms.com/MessageRecord/${e}`,
          data,
          config
        );
        console.log("res", res);
        setLoadingConfirm(false);
        if (res.status === 201) {
          getDataSearch(currentPage);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: res?.data?.message,
            life: 3000,
          });
        } else {
          console.log(res);
        }
      } catch (error) {
        setLoadingConfirm(false);
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error in Data",
          life: 3000,
        });
        //  setError(true);
      }
    };
    return (
      <div className="p-2">
        <div class="card col-12 ">
          <div class="card-body">
            <h5 className="text-primary">Information {data.name}</h5>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block">
                <label className="text-orange ">{t("Message :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>{data?.message}</span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Sender Name :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>{data?.senderName}</span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block ">
                <label className="text-orange ">{t("Send To :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                {data?.totalPhoneNumbers?.length > 0 ? (
                  <>
                    <div>
                      {data?.totalPhoneNumbers.slice(0, 2).map((e) => (
                        <span>{e},</span>
                      ))}
                      <span
                        className="cursor-pointer"
                        onClick={() => downloadArrayAsFile()}
                      >
                        {" "}
                        {t("More Upload File")}
                      </span>
                    </div>
                  </>
                ) : (
                  <>notfound</>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-3 d-md-block d-none">
                <label className="text-orange ">
                  {t("Send Successfully to :")}
                </label>
              </div>
              <div class="form-floating  col-md-9 col-12">
                <span>
                  {data?.successPhoneNumbers?.length > 0 ? (
                    <>
                      {" "}
                      <div>
                        {data?.successPhoneNumbers.slice(0, 2).map((e) => (
                          <span>{e},</span>
                        ))}
                        {t("More Upload File")}
                      </div>
                    </>
                  ) : (
                    <>not found</>
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">
                  {t("Faild Sent To :")}
                  <div>
                    {data?.failedPhoneNumbers.slice(0, 2).map((e) => (
                      <span>{e},</span>
                    ))}
                    {/* {t("More Upload File")} */}
                  </div>{" "}
                </label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span></span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Message Cost :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>
                  {data?.totalPoints / data?.totalPhoneNumbers?.length}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Sent Cost :")}</label>
              </div>
              <div class="form-floating  col-md-10 col-12">
                <span>{data?.totalPoints}</span>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-0">
              <button
                type="submit"
                className="btn btn-primary rounded-pill btn-login  mb-2"
                disabled={loadingConfirm ? true : false}
                onClick={() => sendConfirm(data._id)}
              >
                {loadingConfirm ? (
                  <Spinner size="sm" className="me-2">
                    {" "}
                    {/* Loading...{" "} */}
                  </Spinner>
                ) : null}
                {t("Send")}
              </button>
              {/* <div className="btn btn-primary rounded-pill mt-0 mb-0 text-nowrap"></div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportToExcel = () => {
    console.log(
      `https://togethersms.com/MessageRecord/export-all-archived?searchField=${globalFilter}&startDate=${startDate}&endDate=${lastDate}`
    );
    fetch(
      `https://togethersms.com/MessageRecord/export-all-archived?searchField=${globalFilter}&startDate=${startDate}&endDate=${lastDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Message Archive.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={expandAll}
      >
        {t("Expand All")}
      </div>
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={collapseAll}
      >
        {t("Collapse All")}
      </div>
    </div>
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();
  
  const [startDate, setStarttDate] = useState("");
  const [lastDate, setLastDate] = useState("");

  const getDataSearch = async (page, value, first, last) => {
    setLoading(true);
    if (!value) {
      value = "";
    }
    if (!first) {
      first = "";
    }
    if (!last) {
      last = "";
    }
    // if (!startDate) {
    //   setStarttDate("");
    // }
    console.log(
      `https://togethersms.com/MessageRecord/findAllArchived` +
        "?page=" +
        page +
        "&searchField=" +
        value +
        `&startDate=${first}&endDate=${last}`
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/findAllArchived` +
          "?page=" +
          page +
          "&searchField=" +
          value +
          `&?startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res);
      setLoading(false);

      if (res) {
        setData(res.data);
        setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter, startDate, lastDate);
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = async (selectedDates) => {
    setSelectedDate(selectedDates[0]);
    // const dateParts = selectedDates[0].split(" ");
    // const dateParts2 = selectedDates[1].split(" ");
    setStarttDate(selectedDates[0]);
    setLastDate(selectedDates[1]);
    // console.log(selectedDates);
    // if (selectedDates[1]) {
    //   await getDataSearch(globalFilter, selectedDates[0], selectedDates[1]);
    // } else {
    //   await getDataSearch(globalFilter, selectedDates[0], "");
    // }
    const formattedDate = selectedDates[0].toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    console.log(formattedDate);
  };

  return (
    <div className="card">
      <Toast ref={toast} />
      <div className="p-2">
        <div className="d-flex align-items-center gap-2 border-0 ">
          <Flatpickr
            className="form-control  border-0 dash-filter-picker shadow "
            onChange={handleDateChange}
            options={{
              mode: "range",
              dateFormat: "d M, Y",
              defaultDate: [new Date()],
            }}
          />
          <div
            className="cursor-pointer input-group-text bg-primary border-primary text-white"
            onClick={() => {
              getDataSearch(1, globalFilter, startDate, lastDate);
            }}
          >
            Search
          </div>
          <div
            className="cursor-pointer input-group-text bg-primary border-primary text-white"
            onClick={() => {
              setStarttDate("");
              setLastDate("");
              getDataSearch(1, globalFilter, "", "");
            }}
          >
            Clear
            {/* <BsFillCalendar2MonthFill /> */}
          </div>
        </div>
        <div className="row">
          <div class="form-floating  col-md-6 col-12  mt-3">
            <input
              id="textInputExample"
              type="text"
              class="form-control"
              onInput={(e) => {
                setGlobalFilter(e.target.value);
                setCurrentPage(1);

                getDataSearch(1, e.target.value, startDate, lastDate);
              }}
              placeholder="Text Input"
            />
            <label for="textInputExample">{t("Search")}</label>
          </div>
          <div className="col-lg-6 col-12 d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary rounded-pill d-flex align-items-center mb-0 mb-2"
              // onClick={expandAll}
            >
              {t("Export the selected")}
            </div>
            <div
              className="btn btn-primary d-flex align-items-center rounded-pill mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={exportToExcel}
            >
              {t("Export all the data")}
            </div>
          </div>
        </div>
      </div>

      <DataTable
        style={{ minHeight: "100vh", border: "1px solid #ccc" }}
        lazy
        loading={loading}
        width={"100%"}
        value={data}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        // onRowExpand={onRowExpand}
        // onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="_id"
        header={header}
        rows={5}
        // rowsPerPageOptions={[5, 10, 25, 50]}
        // tableStyle={{ minWidth: "50rem" }}
        // paginator
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: ".5rem" }} />

        <Column
          expander={allowExpansion}
          style={{ width: "2rem" }}
          className="fs-14 "
        />
        <Column
          header="message"
          style={{ width: "8rem" }}
          body={(rowData) => {
            return <div>{rowData.message.slice(0, 10)}</div>;
          }}
          className="fs-14 "
        />
        <Column
          field="name"
          header="SMS no. :3666"
          sortable
          body={(rowData) => {
            return <div>{rowData.totalPhoneNumbers.length}</div>;
          }}
          className="fs-13 "
        />
        <Column
          header="Drafts"
          sortable
          body={(rowData) => {
            return (
              <React.Fragment>
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <Moment
                    format="YYYY/MM/DD"
                    date={rowData.createdAt}
                    style={{ marginTop: "-10px" }}
                  />{" "}
                </div>
              </React.Fragment>
            );
          }}
          className="fs-14 "
        />
        <Column
          body={(rowData) => {
            return <div>{rowData.totalPoints}</div>;
          }}
          header="Point"
          sortable
          className="fs-14 "
        />
      </DataTable>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />
    </div>
  );
}
