import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { ProductService } from "./ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import * as XLSX from "xlsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";

export default function OneGroup({ setNmaeGroup }) {
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);

  const openNew = () => {
    setProduct();
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setEditRow(product);
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };

    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };
  const parms = useParams().id;

  const leftToolbarTemplate = () => {
    return (
      <div className="flex  flex-wrap fs-5 gap-3">
        {/* <Dropdown style={{ display: "contents" }} className="mb-2">
          <Dropdown.Toggle
            variant="success"
            className="btn btn-green rounded-xl mx-1 fs-14 text-white mb-2"
            id="dropdown-basic"
          >
            {t("Actions")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1" className="w-100">
              <button
                label="Delete"
                icon="pi pi-trash"
                severity="danger"
                onClick={confirmDeleteSelected}
                disabled={!selectedProducts || !selectedProducts.length}
                className="btn btn-danger rounded-xl  fs-14  w-100 mb-2"
              >
                {t("Delete")}
                <i class="uil uil-shopping-basket mx-2"></i>
              </button>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        {/* <button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Add Contact")}
          <i class="uil uil-plus mx-2"></i>
        </button>{" "} */}
        {/* <button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={() => setImportExcel(true)}
          className="btn btn-secondary text-white rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Upload")}
          <i class="uil uil-export  mx-2"></i>
        </button>{" "} */}
        <button
          label="Export Selected to Excel"
          icon="pi pi-file-excel"
          onClick={exportAllToExcel}
          className="btn btn-info text-white  rounded-xl mx-1 mb-2 fs-14"
          // disabled={!selectedProducts || !selectedProducts.length}
        >
          {t("Export")}
          <i class="uil uil-import mx-2"></i>
        </button>{" "}
      </div>
    );
  };

  // const rightToolbarTemplate = () => {
  //   return (
  //     <Button
  //       label="Export"
  //       icon="pi pi-upload"
  //       className="p-button-help"
  //       onClick={exportCSV}
  //     />
  //   );
  // };

  // const imageBodyTemplate = (rowData) => {
  //   return (
  //     <img
  //       src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`}
  //       alt={rowData.image}
  //       className="shadow-2 border-round"
  //       style={{ width: "64px" }}
  //     />
  //   );
  // };

  // const priceBodyTemplate = (rowData) => {
  //   return formatCurrency(rowData.price);
  // };

  // const ratingBodyTemplate = (rowData) => {
  //   return <Rating value={rowData.rating} readOnly cancel={false} />;
  // };

  // const statusBodyTemplate = (rowData) => {
  //   return (
  //     <Tag
  //       value={rowData.inventoryStatus}
  //       severity={getSeverity(rowData)}
  //     ></Tag>
  //   );
  // };

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case "INSTOCK":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return null;
    }
  };

  // const header = (

  // );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => deleteMultipleHandle()}
      />
    </React.Fragment>
  );
  const exportSelectedToExcel = () => {
    if (selectedProducts && selectedProducts.length > 0) {
      const selectedRows = products.filter((product) =>
        selectedProducts.includes(product.id)
      );

      const exportData = selectedRows.map((product) => ({
        Name: product.name,
        Description: product.description,
        Price: product.price,
        Category: product.category,
        Rating: product.rating,
        InventoryStatus: product.inventoryStatus,
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SelectedProducts");

      XLSX.writeFile(wb, "selected_products.xlsx");
    }
  };
  const nameBodyTemplate = (rowData) => {
    return <Link to={`/smspro-groups_grid/${rowData.id}`}>{rowData.name}</Link>;
  };
  const [checked, setChecked] = useState(false);

  const handleToggleChange = (rowData, value) => {
    updateStatus(rowData);
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData._id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <InputSwitch
        checked={rowData.blackList || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});
  const handleExcelUpload = (event) => {
    const file = event.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Assuming first sheet

      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setProducts((prevProducts) => [...prevProducts, ...jsonData]);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Data Imported",
        life: 3000,
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const handleUploadExcel = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setUploadedExcelData(jsonData);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Excel Data Uploaded",
        life: 3000,
      });
    };

    reader.readAsArrayBuffer(file);
  };
  const addExcelToTable = () => {
    setProducts((prevProducts) => [...prevProducts, ...uploadedExcelData]);
    setUploadedExcelData([]);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Excel Data Added to Table",
      life: 3000,
    });
  };

  const [editRow, setEditRow] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [importExcel, setImportExcel] = useState(false);
  const [t, i18n] = useTranslation();
  const { user, token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const getDataSearch = async (page, value) => {
    if (!value) {
      value = "";
    }
    setLoading(true);
    console.log(
      `https://togethersms.com/contact` + "?page=" + page + "&searchField=" + value
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/contact` +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      if (res) {
        setData(res.data);
        setTotalPages(res.total / 10);
        // setNmaeGroup(res?.data?.group?.name);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  ///sendData
  const sendData = async () => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    setLoading(true);
    let data = {
      name,
      phoneNumber,
      group: parms,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/contact/create-contact`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        getDataSearch(currentPage);
        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };
  // const updateStatus = async (e) => {
  //   // const countryCode = phoneNumber.substring(1);
  //   hideDialog();
  //   setLoading(true);
  //   let data = {
  //     phoneNumber: e.phoneNumber,
  //   };

  //   try {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //         withCredentials: false,
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     };
  //     const res = await axios.patch(
  //       `https://togethersms.com/contact/update-contact-blacklist`,
  //       data,
  //       config
  //     );
  //     console.log(res);
  //     if (res) {
  //       try {
  //         const config = {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Access-Control-Allow-Origin": "*",
  //             withCredentials: false,
  //             Authorization: `Bearer ${user.token}`,
  //           },
  //         };
  //         const resData = await axios.get(
  //           `https://togethersms.com/contact/get-group-contacts/` +
  //             parms +
  //             "?page=" +
  //             currentPage,

  //           config
  //         );

  //         if (resData.status === 200) {
  //           setData(resData.data.group.contact);
  //           setTotalPages(resData.total / 10);
  //           setLoading(false);
  //           toast.current.show({
  //             severity: "success",
  //             summary: "Successful",
  //             detail: res?.data?.message,
  //             life: 3000,
  //           });
  //         } else {
  //           console.log(res);
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         console.log(error);

  //         //  setError(true);
  //       }
  //     } else {
  //       console.log(res);
  //     }
  //   } catch (error) {
  //     console.log(error);

  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: error?.response?.data?.message,
  //       life: 3000,
  //     });
  //     //  setError(true);
  //   }
  // };

  const updateStatus = async (e) => {
    hideDialog();
    setLoading(true);
    let data = {
      phoneNumber: e.phoneNumber,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const status = await axios.patch(
        `https://togethersms.com/contact/update-contact-blacklist`,
        data,
        config
      );
      if (status.status === 200) {
        console.log(
          `https://togethersms.com/contact/get-group-contacts/` +
            parms +
            "?page=" +
            currentPage +
            "&searchField=" +
            globalFilter
        );
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${user.token}`,
            },
          };
          const res = await axios.get(
            `https://togethersms.com/contact/get-group-contacts/` +
              parms +
              "?page=" +
              currentPage +
              "&searchField=" +
              globalFilter,

            config
          );
          console.log(res);
          setLoading(false);
          if (res) {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: status?.data?.message,
              life: 3000,
            });
            setLoading(false);
            setData(res.data);
            setTotalPages(res.total / 10);
          } else {
            console.log(res);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);

          //  setError(true);
        }
      } else {
        console.log(status);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const handleEdit = async () => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    console.log(editRow);
    setLoading(true);
    let data = {
      name: name ? name : editRow.name,
      phoneNumber: phoneNumber
        ? phoneNumber.slice(1)
        : editRow.phoneNumber.slice(1),
    };
    console.log(data);

    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/contact/update-contact/${editRow.user._id}/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.message,
          life: 3000,
        });
        getDataSearch(currentPage);
        setEditRow("");
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
        setEditRow("");
      }
    } catch (error) {
      // setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      console.log(error);
      setEditRow("");
      setLoading(false);
      //  setError(true);
    }
  };
  const [uploadExcel, setUploadExcel] = useState("");
  const [exp, setExport] = useState("");
  const [err, setError] = useState("");
  const exportToExcel = () => {
    console.log(exp);
    if (exp) {
      const blob = new Blob([exp], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "error.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setError(false);
    }
  };
  const saveProduct = async () => {
    setSubmitted(false);
    setProductDialog(false);
    // console.log(uploadExcel);

    if (uploadExcel) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${user.token}`,
          },
        };
        const res = await axios.post(
          `https://togethersms.com/importer/contacts-import/${parms}`,
          { file: uploadExcel },

          config
        );
        // console.log(res);
        if (res.status === 201) {
          if (res.data.errorFile) {
            setError(true);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: res?.data?.message,
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Excel Data Added to Table",
              life: 3000,
            });
          }
          console.log(res);
          fetch(`https://togethersms.com/importer${res.data.errorFile}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((data) => {
              setExport(data);
            })
            .catch((error) => {});

          getDataSearch(currentPage);
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.log(error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error?.response?.data?.message,
          life: 3000,
        });
      }
    }
  };

  const deleteMultipleHandle = async () => {
    hideDeleteProductsDialog();

    console.log(selectedProducts);

    const idArray = selectedProducts.map((item) => item._id);
    const data = {
      contactIds: idArray,
    };
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/contact/delete-contact`,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage);

        //  dispatch(adminVerfiy(data));

        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const [exportAll, setExportAll] = useState("");
  const exportAllToExcel = () => {
    fetch(`https://togethersms.com/contact/export-group-contacts/${parms}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
        setExportAll(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   fetch(`https://togethersms.com/contact/export-group-contacts/${parms}`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${user.token}`,
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.blob();
  //     })
  //     .then((data) => {
  //       setExportAll(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, [token, data]);
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <i class="uil uil-comment-alt fs-17 text-info cursor-pointer"></i>
          <Link
            to={`/smspro-groups_grid/${rowData.id}/quick-send/${rowData.CONTACTS}`}
          >
            <i class="uil uil-telegram-alt fs-17 text-green cursor-pointer"></i>
          </Link>
          <i
            class="uil uil-edit fs-17 text-blue cursor-pointer"
            icon="pi pi-pencil mr-2"
            onClick={() => {
              setName(rowData.name);
              setphoneNumber(rowData.phoneNumber);
              setEditRow(rowData);
              // editProduct(rowData);
            }}
          ></i>
          <div className="d-flex gap-2">
            <Button
              className=" bg-danger py-1 px-2"
              onClick={() => confirmDeleteProduct(rowData)}
            >
              <i className="ri-delete-bin-2-line bg-danger"></i>
            </Button>
          </div>
          {/* <i
              class="uil uil-backspace fs-17 text-red cursor-pointer"
              icon="pi pi-trash"
              rounded
              outlined
              severity="danger"
            ></i> */}
        </div>
      </React.Fragment>
    );
  };
  const [rowDelete, setRowDelete] = useState("");

  const handleDelete = async () => {
    // const countryCode = phoneNumber.substring(1);
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");
    console.log("khkhj", rowDelete, "asd");
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    const data = {
      contactIds: [rowDelete._id],
    };
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/contact/delete-contact`,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage);

        //  dispatch(adminVerfiy(data));

        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const productDialogFooter2 = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const actionEdit = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-gradient	 py-1 px-2"
            onClick={() => editProduct(rowData)}
          >
            <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          width={"100%"}
          ref={dt}
          loading={loading}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          // header={header}
          selectionMode={"checkbox"}
          totalRecords={20}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            className="fs-13 "
            field="phoneNumber"
            header="Contacts"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            className="fs-13"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData.createdAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header={t("Created At")}
            sortable
            style={{ minWidth: "2rem" }}
          ></Column>
          <Column
            className="fs-13"
            // field="inventoryStatus"
            header="Status"
            body={statusBodyTemplate2}
            // sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          {/* <Column
            className="fs-13"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column> */}
          <Column
            className="fs-13"
            header="Edit"
            body={actionEdit}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10}
        rows={10}
        totalRecords={totalPages * 10}
        onPageChange={onPageChange}
      />

      <Dialog
        visible={editRow}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Edit")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => handleEdit()}
            />
          </React.Fragment>
        }
        onHide={() => setEditRow("")}
      >
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Name")}
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              class="form-control"
              defaultValue={editRow.name}
              placeholder="Text Input"
              onChange={(e) => setName(e.target.value)}
              required
              //   autoFocus
              autoFocus
            />
            {submitted && !product.name && (
              <small className="p-error">{t("Name is required.")}</small>
            )}
            <label for="textInputExample">{t("Name")}</label>
          </div>
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Phone")}
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              defaultValue={editRow.phoneNumber}
              class="form-control"
              placeholder="Text Input"
              onChange={(e) => setphoneNumber(e.target.value)}
              required
              //   autoFocus
            />
            {submitted && !product.phone && (
              <small className="p-error">{t("Phone is required.")}</small>
            )}
            <label for="textInputExample">{t("Phone")}</label>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={importExcel}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Upload Excel"
        modal
        className="p-fluid"
        footer={productDialogFooter2}
        onHide={() => setImportExcel(false)}
      >
        <>
          {!uploadExcel && (
            <div className="d-flex align-items-center justify-content-between ">
              <div class="form-select-wrapper col-3 d-md-block d-none">
                <label className="text-orange ">{t("Upload Excel")} </label>
              </div>
              <div>
                <button
                  label="Upload Excel"
                  icon="pi pi-upload"
                  className="btn btn-primary rounded-xl mx-1 fs-14"
                  onClick={() =>
                    document.getElementById("upload-excel-input").click()
                  }
                >
                  {t("Upload")}
                  <i class="uil uil-export  mx-2"></i>
                </button>
              </div>

              <input
                id="upload-excel-input"
                type="file"
                accept=".xlsx, .xls"
                style={{ display: "none" }}
                onChange={(e) => {
                  setUploadExcel(e.target.files[0]);
                }}
                //   onChange={handleUploadExcel}
              />
            </div>
          )}
          {uploadExcel && (
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <svg
                  className="fs-10 w-10"
                  xmlns="https://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 48 48"
                >
                  <rect
                    width="16"
                    height="9"
                    x="28"
                    y="15"
                    fill="#21a366"
                  ></rect>
                  <path
                    fill="#185c37"
                    d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"
                  ></path>
                  <rect
                    width="16"
                    height="9"
                    x="28"
                    y="24"
                    fill="#107c42"
                  ></rect>
                  <rect
                    width="16"
                    height="9"
                    x="12"
                    y="15"
                    fill="#3fa071"
                  ></rect>
                  <path
                    fill="#33c481"
                    d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"
                  ></path>
                  <path
                    fill="#21a366"
                    d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"
                  ></path>
                  <path
                    d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z"
                    opacity=".05"
                  ></path>
                  <path
                    d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z"
                    opacity=".07"
                  ></path>
                  <path
                    d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z"
                    opacity=".09"
                  ></path>
                  <linearGradient
                    id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1"
                    x1="4.725"
                    x2="23.055"
                    y1="14.725"
                    y2="33.055"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#18884f"></stop>
                    <stop offset="1" stop-color="#0b6731"></stop>
                  </linearGradient>
                  <path
                    fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)"
                    d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z"
                  ></path>
                </svg>
                <div>{uploadExcel.name}</div>
              </div>
              <button
                icon="pi pi-trash"
                severity="danger"
                onClick={() => setUploadExcel("")}
                className="btn btn-danger rounded-xl  fs-20   mb-2"
              >
                <i class="uil uil-shopping-basket "></i>
              </button>
            </div>
          )}
        </>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete")}
              <b>{rowDelete.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />

          <span>
            {t("Are you sure you want to delete the selected products?")}
          </span>
        </div>
      </Dialog>
    </div>
  );
}
