import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "react-tooltip/dist/react-tooltip.css";

import { Tooltip } from "react-tooltip";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
// import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";
import { Spinner } from "react-bootstrap";
export default function SenderNameTable() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [addNumberInGroup, setAddNumberInGroup] = useState(false);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");
  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const [t, i18n] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const getData = async (page) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/admin` + "?page=" + page,

        config
      );
      console.log(res);
      if (res) {
        setData(res.data);

        setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  const getDataSearch = async (value, page) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/admin` +
          "?page=" +
          page +
          "&&searchField=" +
          value,

        config
      );
      console.log(res);
      if (res) {
        setData(res.data);

        setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  ///sendData
  const sendData = async (e) => {
    e.preventDefault();
    setName("");
    if (name != "") {
      hideDialog();
    }
    setLoading(true);
    let data = {
      name,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/Group/create`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        getData();
        // setLoading(false);
        setLoading(false);
        setName("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };
  const [countryName, setCountryName] = useState(false);
  const [senderName, setSenderName] = useState(false);
  const [activity, setActivity] = useState(false);
  const [facebookLink, setFacebookLink] = useState(false);
  const [websiteLink, setWebsiteLink] = useState(false);
  const [commercialRegister, setcommercialRegister] = useState("");
  const [taxId, settaxId] = useState("");
  const [delegation, setdelegation] = useState("");

  const handleEdit = async () => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    const formData = new FormData();
    formData.append("websiteLink", websiteLink);
    formData.append("facebookLink", facebookLink);
    formData.append("activity", activity);
    formData.append("senderName", senderName);
    formData.append("countryName", countryName);
    formData.append("commercialRegister", commercialRegister);
    formData.append("taxId", taxId);
    formData.append("delegation", delegation);
    console.log(countryName);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-by-admin/${editRow.user._id}/${editRow._id}`,
        formData,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const [openBlock, setOpenBlock] = useState("");

  const handleAccept = async (editRow) => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(
      `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`
    );

    const data = { activationStatus: "Activated" };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handlePaid = async (editRow) => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(
      `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`
    );

    const data = { activationStatus: "Activated" };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const [comment, setComment] = useState("");
  const handleUnBlock = async (editRow) => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(
      `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`
    );

    const data = {
      activationStatus: "Activation Rejected",
      rejectedReasons: comment,
    };
    console.log(data);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      setOpenBlock("");
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenBlock("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handleDelete = async () => {
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/CompanyActivation/delete-by-admin/${rowDelete._id}`,
        config,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //   toast.current.show({
      //     severity: "error",
      //     summary: "Error",
      //     detail: error,
      //     life: 3000,
      //   });
      //  setError(true);
    }
  };

  const downloadcommercialRegister = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        responseType: "blob",
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/download-commercialRegister/${e.user._id}/${e._id}`,

        config
      );
      console.log(res, "1");
      if (res) {
        //  dispatch(adminVerfiy(data));
        // getData();
        if (res) {
          console.log(res, "asd");
          const blob = new Blob([res], {
            type: res.type,
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "commercialRegister";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }

    fetch(`https://togethersms.com/Group/export-selected-group`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(array),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.log(response.json());
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadtaxId = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        responseType: "blob",
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/download-taxId/${e.user._id}/${e._id}`,

        config
      );
      console.log(res, "1");
      if (res) {
        //  dispatch(adminVerfiy(data));
        // getData();
        if (res) {
          console.log(res, "asd");
          const blob = new Blob([res], {
            type: res.type,
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "commercialRegister";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }

    fetch(`https://togethersms.com/Group/export-selected-group`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(array),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.log(response.json());
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloaddelegation = async (e) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        responseType: "blob",
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/download-delegation /${e.user._id}/${e._id}`,

        config
      );
      console.log(res, "1");
      if (res) {
        //  dispatch(adminVerfiy(data));
        // getData();
        if (res) {
          console.log(res, "asd");
          const blob = new Blob([res], {
            type: res.type,
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "commercialRegister";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }

    fetch(`https://togethersms.com/Group/export-selected-group`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(array),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.log(response.json());
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ///////////

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditRow("");
    setSubmitted(false);
    setProductDialog(false);
  };
  const handleAddOneInGrroup = () => {
    setAddNumberInGroup(true);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setEditRow(product);
    setProductDialog(true);
  };

  const [rowDelete, setRowDelete] = useState("");

  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
      <span className="p-input-icon-left col-12 col-md-6">
        <i className="pi pi-search" />
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="search"
            onInput={(e) => {
              setCurrentPage(1);
              setGlobalFilter(e.target.value);

              getDataSearch(e.target.value, currentPage);
            }}
            placeholder="Search..."
            class="form-control"
          />
          <label for="textInputExample">{t("Search...")}</label>
        </div>
      </span>
    </div>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <Link to={`/smspro-groups_grid/${rowData.group._id}`}>
        {rowData.group.name}
      </Link>
    );
  };

  const handleToggleChange = (rowData, value) => {
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData._id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <>
        {/* {rowData.activationStatus == "Activation in progress" && ( */}
        {/* <div className="d-flex gap-2">
          {rowData?.activationStatus != "Activated" && (
            <>
              <button
                label="New"
                icon="pi pi-plus"
                severity="success"
                className="btn btn-success rounded-xl mx-1 fs-14 "
                onClick={() => handleAccept(rowData)}
              >
                Accept
              </button>
            </>
          )}
          <button
            label="New"
            icon="pi pi-plus"
            severity="success"
            className="btn btn-danger rounded-xl mx-1 fs-14 "
            onClick={() => setOpenBlock(rowData)}
          >
            Block
          </button>
        </div> */}
        <InputSwitch
          checked={rowData.activationStatus == "Activated" || false}
          onChange={(e) => {
            if (rowData.activationStatus == "Activated") {
              setOpenBlock(rowData);
            } else {
              handleAccept(rowData);
            }
            // handleToggleChange(rowData, e.value);
          }}
        />
        {/* )} */}
      </>

      // <InputSwitch
      //   checked={checkedRows[rowData._id] || false}
      //   onChange={(e) => handleToggleChange(rowData, e.value)}
      // />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <i
            class="uil uil-edit fs-17 text-blue cursor-pointer"
            icon="pi pi-pencil mr-2"
            onClick={() => {
              editProduct(rowData);
            }}
          ></i>

          <div className="d-flex gap-2">
            <Button
              className=" bg-danger py-1 px-2"
              onClick={() => confirmDeleteProduct(rowData)}
            >
              <i className="ri-delete-bin-2-line bg-danger"></i>
            </Button>
          </div>

          <i class="uil uil-copy  fs-17 text-green cursor-pointer"></i>

          <i
            class="uil uil-plus-circle fs-17 text-green cursor-pointer"
            onClick={handleAddOneInGrroup}
          ></i>
        </div>
      </React.Fragment>
    );
  };

  const actionEdit = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-gradient	 py-1 px-2"
            onClick={() => editProduct(rowData)}
          >
            <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  //delete
  const [userName, setUser] = useState("");
  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}

        <DataTable
          width={"100%"}
          ref={dt}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          header={header}
          // selectionMode={"checkbox"}
          totalRecords={20}
        >
          {/* <Column selectionMode="multiple" exportable={false}></Column> */}
          <Column
            header="Order_id"
            style={{ width: "8rem" }}
            body={(rowData) => {
              return <div>{rowData.order}</div>;
            }}
            className="fs-14 "
          />
          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            field="senderName"
            header="Sender Name"
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div
                    className="d-flex align-items-center gap-2 cursor-pointer"
                    style={{ cursor: "pointer" }}
                    onClick={() => setUser(rowData.user)}
                  >
                    {rowData.user.firstName}
                  </div>
                </React.Fragment>
              );
            }}
            header="Name"
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            field="paymentMethod"
            header="paymentMethod"
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-13"
            header="Country Name"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    {rowData?.country?.countryName}
                  </div>
                </React.Fragment>
              );
            }}
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            className="fs-13"
            field="activationStatus"
            body={(rowData) => {
              return (
                <>
                  {rowData.activationStatus == "Activation in progress" ? (
                    <div className="text-blue d-flex align-items-center gap-1">
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "blue",
                          display: "block",
                          minWidth: "10px",
                        }}
                      ></span>{" "}
                      {rowData.activationStatus}
                    </div>
                  ) : rowData.activationStatus == "Activated" ? (
                    <div className="text-green d-flex align-items-center gap-1">
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          display: "block",
                          minWidth: "10px",
                        }}
                      ></span>{" "}
                      {rowData.activationStatus}
                    </div>
                  ) : (
                    <div className="text-danger d-flex align-items-center gap-1">
                      {" "}
                      <span
                        className="my-anchor-element"
                        id="my-anchor-element"
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          display: "block",
                        }}
                      ></span>{" "}
                      {rowData.activationStatus}
                      <Tooltip anchorSelect=".my-anchor-element" place="top">
                        {rowData?.rejectedReasons}
                      </Tooltip>{" "}
                    </div>
                  )}
                </>
              );
            }}
            header="Status"
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            className="fs-13"
            field="isPaid"
            header="isPaid"
            style={{ minWidth: "4rem" }}
          ></Column>

          {/* <Column
            className="fs-13 w100"
            body={(rowData) => {
              return (
                
              );
            }}
            header="Commercial Register"
            style={{ minWidth: "10rem" }}
          ></Column> */}
          {/* <Column
            className="fs-13"
            body={(rowData) => {
              return (
               
              );
            }}
            header="taxId"
            style={{ minWidth: "4rem" }}
          ></Column> */}
          {/* <Column
            className="fs-13"
            body={(rowData) => {
              return (
               
              );
            }}
            header="Delegation"
            style={{ minWidth: "4rem" }}
          ></Column> */}

          <Column
            className="fs-13"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData.createAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header={t("Created At")}
            style={{ minWidth: "2rem" }}
          ></Column>

          <Column
            className="fs-13"
            header="Edit"
            body={actionEdit}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            className="fs-13"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />

      <Dialog
        visible={productDialog}
        style={{ width: "45rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow?.senderName ? t("Edit Sender Name") : t("Sender Name")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) =>
                editRow?.senderName ? handleEdit() : sendData(e)
              }
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendData();
          }}
        >
          <div class="card-body">
            <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
              <div class="form-select-wrapper  d-md-block d-none">
                <label className="text-orange ">{t("Status")}</label>
              </div>
              <div class="form-floating  ">
                <div className="d-flex gap-2">
                  <button
                    label="New"
                    icon="pi pi-plus"
                    severity="success"
                    className="btn btn-success rounded-xl mx-1 fs-14 "
                    onClick={() => handleAccept(editRow)}
                  >
                    Accept
                  </button>

                  <button
                    label="New"
                    icon="pi pi-plus"
                    severity="success"
                    className="btn btn-danger rounded-xl mx-1 fs-14 "
                    onClick={() => setOpenBlock(editRow)}
                  >
                    Block
                  </button>
                </div>
                {/* <InputSwitch
                  checked={editRow.activationStatus == "Activated" || false}
                  onChange={(e) => {
                    if (editRow.activationStatus == "Activated") {
                      setOpenBlock(editRow);
                    } else {
                      handleAccept(editRow);
                    }
                    // handleToggleChange(rowData, e.value);
                  }}
                /> */}
              </div>
            </div>
            <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
              <div class="form-select-wrapper  d-md-block d-none">
                <label className="text-orange ">{t("Paid")}</label>
              </div>
              <div class="form-floating  ">
                <InputSwitch
                  checked={editRow.activationStatus == "Activated" || false}
                  onChange={(e) => {
                    if (editRow.activationStatus == "Activated") {
                      setOpenBlock(editRow);
                    } else {
                      handleAccept(editRow);
                    }
                    // handleToggleChange(rowData, e.value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Select Country")}</label>
              </div>
              <div class="form-floating  col-md-10  col-12">
                <div class="form-floating ">
                  <div className="form-select-wrapper">
                    <select
                      className="form-select"
                      required
                      aria-label="Default select example"
                      onChange={(e) => setCountryName(e.target.value)}
                      defaultValue={editRow?.country?.countryName}
                    >
                      <option selected> {t("Select Country")}</option>
                      <option value={"Egypt"}>Egypt </option>
                      <option value={"Saudi Arabia"}>Saudi</option>
                      <option value={"United Arab Emirates"}> </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Sender Name")}</label>
              </div>
              <div class="form-floating  col-md-10  col-12">
                <div class="form-floating ">
                  <input
                    id="textInputExample"
                    type="text"
                    class="form-control"
                    placeholder="Text Input"
                    defaultValue={editRow.senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                    required
                  />
                  <label for="textInputExample">{t("Sender Name")}</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange "> {t("Type Of Activity")}</label>
              </div>
              <div class="form-floating  col-md-10  col-12">
                <div class="form-floating ">
                  <input
                    onChange={(e) => setActivity(e.target.value)}
                    required
                    id="textInputExample"
                    defaultValue={editRow.activity}
                    type="text"
                    class="form-control"
                    placeholder="Text Input"
                  />
                  <label for="textInputExample">{t("Type Of Activity")}</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Facebook Link")}</label>
              </div>
              <div class="form-floating  col-md-10  col-12">
                <div class="form-floating ">
                  <input
                    onChange={(e) => setFacebookLink(e.target.value)}
                    id="textInputExample"
                    defaultValue={editRow.facebookLink}
                    type="text"
                    class="form-control"
                    placeholder="Text Input"
                  />
                  <label for="textInputExample">{t("Facebook Link")}</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div class="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange "> {t("Website Link")}</label>
              </div>
              <div class="form-floating  col-md-10  col-12">
                <div class="form-floating ">
                  <input
                    onChange={(e) => setWebsiteLink(e.target.value)}
                    id="textInputExample"
                    defaultValue={editRow.websiteLink}
                    type="text"
                    class="form-control"
                    placeholder="Text Input"
                  />
                  <label for="textInputExample">{t("Website Link")}</label>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex align-items-center mb-4">
                <div class="form-select-wrapper  col-4 d-md-block ">
                  <label className="text-orange ">
                    {t("Commercial register")}
                  </label>
                </div>
                <div className=" col-md-4  col-6 d-flex  justify-content-end">
                  <React.Fragment>
                    {editRow?.commercialRegister?.length > 0 ? (
                      <div className="d-flex gap-2">
                        <Button
                          type="button"
                          className=" bg-gradient	 py-1 px-2"
                          onClick={() => downloadcommercialRegister(editRow)}
                        >
                          <i className=" ri-chat-download-fill text-info-emphasis cursor-pointe"></i>
                        </Button>
                      </div>
                    ) : (
                      "Not Found"
                    )}
                  </React.Fragment>
                </div>
                <input
                  onChange={(e) => setcommercialRegister(e.target.files[0])}
                  id="register"
                  type="file"
                  accept=".pdf ,.png,.jpg, .xls"
                  style={{ display: "none" }}
                  //   onChange={handleUploadExcel}
                />
              </div>
              <div className="d-flex align-items-center mb-4">
                <div class="form-select-wrapper mb-4 col-4  d-md-block ">
                  <label className="text-orange ">{t("Tax id")}</label>
                </div>
                <div className="mb-4 col-md-4  col-6 d-flex  justify-content-end">
                  <React.Fragment>
                    {editRow?.taxId?.length > 0 ? (
                      <div className="d-flex gap-2">
                        <Button
                          type="button"
                          className=" bg-gradient	 py-1 px-2"
                          onClick={() => downloadtaxId(editRow)}
                        >
                          <i className=" ri-chat-download-fill text-info-emphasis cursor-pointe"></i>
                        </Button>
                      </div>
                    ) : (
                      "Not Found"
                    )}
                  </React.Fragment>
                </div>
                <input
                  onChange={(e) => settaxId(e.target.files[0])}
                  id="tax"
                  type="file"
                  accept=".pdf ,.png,.jpg, .xls"
                  style={{ display: "none" }}
                  //   onChange={handleUploadExcel}
                />
              </div>
              <div className="d-flex align-items-center mb-4mb-4">
                <div class="form-select-wrapper mb-4 col-4  d-md-block">
                  <label className="text-orange ">
                    {t("Delegation (Download Sample File)")}
                  </label>
                </div>
                <div className="mb-4 col-md-4  col-6 d-flex  justify-content-end">
                  <React.Fragment>
                    {editRow?.delegation?.length > 0 ? (
                      <div className="d-flex gap-2">
                        <Button
                          type="button"
                          className=" bg-gradient	 py-1 px-2"
                          onClick={() => downloaddelegation(editRow)}
                        >
                          <i className=" ri-chat-download-fill text-info-emphasis cursor-pointe"></i>
                        </Button>
                      </div>
                    ) : (
                      "Not Found"
                    )}
                  </React.Fragment>
                </div>
                <input
                  onChange={(e) => setdelegation(e.target.files[0])}
                  id="Delegation"
                  type="file"
                  accept=".pdf ,.png,.jpg, .xls"
                  style={{ display: "none" }}
                  //   onChange={handleUploadExcel}
                />
              </div>

              {/* <div class="form-check my-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  {" "}
                  {t("Default checkbox")}
                </label>
              </div> */}
            </div>
          </div>
        </form>
      </Dialog>
      <Dialog
        visible={openBlock}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow.name ? t(" Rejected Reason") : t(" Rejected Reason")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={() => setOpenBlock("")}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => handleUnBlock(openBlock)}
            />
          </React.Fragment>
        }
        onHide={() => setOpenBlock("")}
      >
        <div className="field">
          <label htmlFor="comment" className="font-bold">
            Comment
          </label>
          <InputText
            id="comment"
            onChange={(e) => setComment(e.target.value)}
            required
            //   autoFocus
            defaultValue={name}
            autoFocus
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete ")}
              <b>{rowDelete?.senderName}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete the selected products?")}
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={userName}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Information"
        modal
        // footer={deleteProductsDialogFooter}
        onHide={() => setUser("")}
      >
        <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
          <div class="form-select-wrapper  d-md-block d-none">
            <label className="text-orange ">{t("Name")}</label>
          </div>
          <div class="form-floating  ">{userName.firstName}</div>
        </div>
        <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
          <div class="form-select-wrapper  d-md-block d-none">
            <label className="text-orange ">{t("Email")}</label>
          </div>
          <div class="form-floating  ">{userName.email}</div>
        </div>
        <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
          <div class="form-select-wrapper  d-md-block d-none">
            <label className="text-orange ">{t("Phone")}</label>
          </div>
          <div class="form-floating  ">{userName.phoneNumber}</div>
        </div>
      </Dialog>
    </div>
  );
}
