import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import logoLight from "../../assets/images/logo-light.png";
//Import config

import { useNavigate } from "react-router-dom/dist";
import PhoneInput from "react-phone-input-2";
import { adminVerfiy } from "../../slices/authSlice";
import axios from "axios";

const EnterPhone = (props) => {
  const { user, error, msg } = useSelector((state) => state.auth);
  const token = localStorage.getItem("tokenSms");

  //   const [error, serErrror] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const phonesend = phone.substring(0);
    setLoading(true);
    console.log(token);

    // setLoading(true);
    // dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/staff/otp-login`,
        { phoneNumber: phonesend },
        config
      );
      console.log("res", res);

      if (res) {
        // dispatch(adminVerfiy(data));

        console.log("res", res);
        navigate("/code-mobile");

        // setLoading(false);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //   setError(true);
    }
  };

  document.title = "Basic SignIn | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      {/* <img src={logoLight} alt="" height="20" /> */}
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    {/* Premium Admin & Dashboard Template */}
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center pt-5">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Enter Your Phone </h5>
                    </div>

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                      >
                        <div className="mb-3 col-12 col-lg-12">
                          <Label htmlFor="useremail" className="form-label">
                            Phone <span className="text-danger">*</span>{" "}
                          </Label>
                          <Input
                            required
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter phone "
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        {/* <div className="mb-3 col-12 col-lg-6">
                          <Label htmlFor="phone" className="form-label">
                            Phone <span className="text-danger">*</span>
                          </Label>
                          <PhoneInput
                            country={"eg"}
                            enableSearch={true}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            placeholder="Enter phone "
                            className="w-100 "
                          />
                        </div> */}

                        <div className="mt-4">
                          <Button
                            disabled={error ? null : loading ? true : false}
                            color="secondary"
                            className="w-100"
                            type="submit"
                          >
                            {error ? null : loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Send
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                {/* <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account ?{" "}
                    <Link
                      to="/register"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Signup{" "}
                    </Link>{" "}
                  </p>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default EnterPhone;
