import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { useSelector } from "react-redux";
import axios from "axios";

const RevenueCharts = ({ dataColors, series }) => {
  var linechartcustomerColors = getChartColorsArray(dataColors);

  const [rightColumn, setRightColumn] = useState(false);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  const currentDate = new Date();
  const defaultStartDate = currentDate.toDateString();
  const defaultLastDate = currentDate.toDateString();
  const { user } = useSelector((state) => state.auth);
  // const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const [startDate, setStarttDate] = useState(defaultStartDate);
  const [lastDate, setLastDate] = useState(defaultLastDate);
  const getDataSearchInfo = async (first, last) => {
    if (!first) {
      first = "";
    }
    if (!last) {
      last = "";
    }

    console.log(
      `https://togethersms.com/MessageRecord/findAll-Finished-TotalCounts` +
        `&startDate=${first}&endDate=${last}`
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/findAll-Finished-ChartCounts` +
          `?startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res, "asd");

      if (res) {
        setDataInfo(res);
        // setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearchInfo(startDate, lastDate);
  }, [startDate, lastDate]);
  console.log(series);
  var options = {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      dashArray: [0, 0, 8],
      width: [2, 0, 2.2],
    },
    fill: {
      opacity: [0.1, 0.9, 1],
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return "$" + y.toFixed(2) + "k";
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " Sales";
            }
            return y;
          },
        },
      ],
    },
  };
  const data = [
    {
      data: [34, 65, 46, 68, 49, 61, 42, 44, 78, 52, 63, 67],
      name: "Orders",
      type: "area",
    },
    {
      data: [
        89.25, 98.58, 68.74, 108.87, 77.54, 84.03, 51.24, 28.57, 92.57, 42.36,
        88.51, 36.57,
      ],
      name: "Earnings",
      type: "bar",
    },
  ];
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={dataInfo && dataInfo}
        type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const StoreVisitsCharts = ({ dataColors }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = [44, 55, 41, 17, 15];
  var options = {
    labels: ["Direct", "Social", "Email", "Other", "Referrals"],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={dataInfo && dataInfo}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { RevenueCharts, StoreVisitsCharts };
