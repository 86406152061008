import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function GetRechargeArchive({ item }) {
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState();
  const getData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/payment/find-All-NewData`,

        config
      );
      setCount(res.total);
      if (res) {
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handleRead = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/payment/readAll`,

        config
      );
      console.log(res);
      if (res) {
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  return (
    <li className="nav-item" onClick={() => handleRead()}>
      <Link className="nav-link menu-link" to={item.link ? item.link : "/#"}>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        <div className="mx-3">{count > 0 && <>({count})</>}</div>
        {item.badgeName ? (
          <span
            className={"badge badge-pill bg-" + item.badgeColor}
            data-key="t-new"
          >
            {item.badgeName}
          </span>
        ) : null}
      </Link>
    </li>
  );
}
