import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { Button } from "primereact/button";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { t } from "i18next";

const Meta = () => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const { user } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");
  const [name, setName] = useState("");
  const [title_ar, settitle_ar] = useState("");
  const [title_en, settitle_en] = useState("");
  const [description_ar, setdescription_ar] = useState("");
  const [description_en, setdescription_en] = useState("");
  const [keyword_ar, setkeyword_ar] = useState("");
  const [keyword_en, setkeyword_en] = useState("");
  const [facebook, setfacebook] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [features_en, setfeatures_en] = useState("");
  const [logo, setlogo] = useState("");
  const toast = useRef(null);
  const getData = async () => {
    console.log(user.token);
    // console.log(`https://togethersms.com/contact/` + parms);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        "https://togethersms.com/MetaDescription/findAll",

        config
      );
      console.log(res, "resres");

      settitle_ar(res[0]?.title_ar);
      settitle_en(res[0]?.title_en);
      setdescription_ar(res[0]?.description_ar);
      setdescription_en(res[0]?.description_en);
      setfacebook(res[0]?.facebook);
      setkeyword_en(res[0]?.keyword_en);
      setkeyword_ar(res[0]?.keyword_ar);
      setyoutube(res[0]?.youtube);
      setinstagram(res[0]?.instagram);
    } catch (error) {
      //  setError(true);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const handleEdit = async () => {
    // const countryCode = phoneNumber.substring(1);
    console.log(editRow);
    console.log(logo);
    let dataSend = {
      title_ar,
      title_en,
      keyword_ar,
      keyword_en,
      description_ar,
      description_en,
    };

    //
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        dataSend,
      };

      const res = await axios.post(
        `https://togethersms.com/MetaDescription/create-metaDesc`,
        dataSend,

        config
      );
      console.log(res);
      if (res) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
        //  dispatch(adminVerfiy(data));
        // getData();
        //
        setEditRow("");
      } else {
        console.log(res);

        setEditRow("");
      }
    } catch (error) {
      //
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });

      setEditRow("");

      //  setError(true);
    }
  };

  document.title = "Listjs | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Settings" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Footer</h4>
                </CardHeader>

                <CardBody className=" d-flex justify-content-between align-items-center flex-wrap">
                  <div class="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Title Arabic")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-10  col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="text"
                            class="form-control"
                            placeholder="Text Input"
                            value={title_ar}
                            onChange={(e) => settitle_ar(e.target.value)}
                            required
                          />
                          <label for="textInputExample">
                            {t("Title Arabic")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Title English")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-10  col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="text"
                            class="form-control"
                            placeholder="Text Input"
                            value={title_en}
                            onChange={(e) => settitle_en(e.target.value)}
                            required
                          />
                          <label for="textInputExample">
                            {t("Title English")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Description Arabic")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-10  col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="text"
                            class="form-control"
                            placeholder="Text Input"
                            value={description_ar}
                            onChange={(e) => setdescription_ar(e.target.value)}
                            required
                          />
                          <label for="textInputExample">
                            {t("Description Arabic")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Description English")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-10  col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="text"
                            class="form-control"
                            placeholder="Text Input"
                            value={description_en}
                            onChange={(e) => setdescription_en(e.target.value)}
                            required
                          />
                          <label for="textInputExample">
                            {t("Description English")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("keyword_ar")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-10  col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="url"
                            class="form-control"
                            placeholder="Text Input"
                            value={keyword_ar}
                            onChange={(e) => setkeyword_ar(e.target.value)}
                            required
                          />
                          <label for="textInputExample">
                            {t("keyword_ar")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("keyword_en")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-10  col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="url"
                            class="form-control"
                            placeholder="Text Input"
                            value={keyword_en}
                            onChange={(e) => setkeyword_en(e.target.value)}
                            required
                          />
                          <label for="textInputExample">
                            {t("keyword_en")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <Button
                      label="Save"
                      icon="pi pi-check"
                      onClick={() => handleEdit()}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
          }}
        >
          {" "}
          Add Customer{" "}
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Customer Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email-field" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email-field"
                className="form-control"
                placeholder="Enter Email"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone
              </label>
              <input
                type="text"
                id="phone-field"
                className="form-control"
                placeholder="Enter Phone no."
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="date-field" className="form-label">
                Joining Date
              </label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                }}
                placeholder="Select Date"
              />
            </div>

            <div>
              <label htmlFor="status-field" className="form-label">
                Status
              </label>
              <select
                className="form-control"
                data-trigger
                name="status-field"
                id="status-field"
                required
              >
                <option value="">Status</option>
                <option value="Active">Active</option>
                <option value="Block">Block</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Customer
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Dialog
        visible={editRow}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit"
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={() => setEditRow(false)}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => handleEdit()}
            />
          </React.Fragment>
        }
        onHide={() => setEditRow("")}
      >
        <div className="field">
          <label htmlFor="description" className="font-bold">
            confirmMessageLimit
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              class="form-control"
              // defaultValue={data[0]?.confirmMessageLimit}
              placeholder="Text Input"
              onChange={(e) => setName(e.target.value)}
              required
              //   autoFocus
              autoFocus
            />
          </div>
        </div>
      </Dialog>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <ModalHeader
          toggle={() => {
            tog_delete();
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Meta;
