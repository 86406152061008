import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Button } from "primereact/button";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import List from "list.js";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

const CreateSenderName = () => {
  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
  };

  const { user } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const toast = useRef(null);
  const getData = async () => {
    console.log(user.token);
    // console.log(`https://togethersms.com/contact/` + parms);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        "https://togethersms.com/smsSenderName",

        config
      );
      console.log(res);
      if (res.status === 200) {
        setData(res);
      } else {
        //  setNmaeGroup(res.name);

        setData(res);

        console.log(res);
      }
    } catch (error) {
      console.log(error);
      //  setError(true);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const [modal_delete, setmodal_delete] = useState(false);
  const tog_delete = () => {
    setmodal_delete(!modal_delete);
  };

  const handleEdit = async () => {
    // const countryCode = phoneNumber.substring(1);
    console.log(editRow);

    let dataSend = {
      name,
    };
    console.log(dataSend);
    //
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
        dataSend,
      };
      const res = await axios.patch(
        `https://togethersms.com/smsSenderName/create-senderName`,

        dataSend,
        config
      );
      console.log(res, "res");

      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: res.message,
        life: 3000,
      });
      //  dispatch(adminVerfiy(data));
      getData();
      //
      setEditRow("");
    } catch (error) {
      //
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });

      setEditRow("");

      //  setError(true);
    }
  };

  document.title = "Listjs | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <CardBody className=" d-flex justify-content-between align-items-center flex-wrap">
        <div className="listjs-table d-flex flex-wrap" id="customerList">
          <h4>Create Sender Name : </h4>
          <h4>{data[0]?.name}</h4>
        </div>
        <div className="listjs-table d-flex flex-wrap">
          <div className="d-flex gap-2">
            <Button
              className=" bg-gradient	 py-1 px-2"
              onClick={() => setEditRow(true)}
            >
              <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
            </Button>
          </div>
        </div>
      </CardBody>

      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_list();
          }}
        >
          {" "}
          Add Customer{" "}
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div className="mb-3" id="modal-id" style={{ display: "none" }}>
              <label htmlFor="id-field" className="form-label">
                ID
              </label>
              <input
                type="text"
                id="id-field"
                className="form-control"
                placeholder="ID"
                readOnly
              />
            </div>

            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Customer Name
              </label>
              <input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email-field" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email-field"
                className="form-control"
                placeholder="Enter Email"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Phone
              </label>
              <input
                type="text"
                id="phone-field"
                className="form-control"
                placeholder="Enter Phone no."
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="date-field" className="form-label">
                Joining Date
              </label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                }}
                placeholder="Select Date"
              />
            </div>

            <div>
              <label htmlFor="status-field" className="form-label">
                Status
              </label>
              <select
                className="form-control"
                data-trigger
                name="status-field"
                id="status-field"
                required
              >
                <option value="">Status</option>
                <option value="Active">Active</option>
                <option value="Block">Block</option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Customer
              </button>
              {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <Dialog
        visible={editRow}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Edit"
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={() => setEditRow(false)}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => handleEdit()}
            />
          </React.Fragment>
        }
        onHide={() => setEditRow("")}
      >
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Create Sender Name
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              class="form-control"
              defaultValue={data[0]?.name}
              placeholder="Text Input"
              onChange={(e) => setName(e.target.value)}
              required
              //   autoFocus
              autoFocus
            />
          </div>
        </div>
      </Dialog>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          tog_delete();
        }}
        className="modal fade zoomIn"
        id="deleteRecordModal"
        centered
      >
        <ModalHeader
          toggle={() => {
            tog_delete();
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CreateSenderName;
