import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
// import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";
export default function AdminTable() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [addNumberInGroup, setAddNumberInGroup] = useState(false);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");
  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const [t, i18n] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const getData = async (page) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/staff` + `?page=${page}`,

        config
      );
      console.log(res);
      if (res) {
        setData(res.data);

        setTotalPages(res.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  const getDataSearch = async (value) => {
    console.log(value);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/staff` + "?page=1&&searchField=" + value,

        config
      );
      console.log(res);
      if (res) {
        setData(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  ///sendData
  const sendData = async (e) => {
    e.preventDefault();
    setName("");
    if (name != "") {
      hideDialog();
    }
    setLoading(true);
    let data = {
      name,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/Group/create`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        getData();
        // setLoading(false);
        setLoading(false);
        setName("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const handleEdit = async () => {
    hideDialog();
    setLoading(true);
    setEditRow("");
    let data = {
      phoneNumber: phoneNumber ? phoneNumber : editRow.phoneNumber,
      firstName: firstName ? firstName : editRow.firstName,
      lastName: lastName ? lastName : editRow.lastName,
      email: email ? email : editRow.email,
    };
    console.log(data);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/user/update-by-admin/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      //   setLoading(false);
      //   toast.current.show({
      //     severity: "error",
      //     summary: "Error",
      //     detail: error,
      //     life: 3000,
      //   });
      //  setError(true);
    }
  };
  const handleDelete = async () => {
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/user/delete-by-admin/${rowDelete._id}`,
        { id: rowDelete._id },
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //   toast.current.show({
      //     severity: "error",
      //     summary: "Error",
      //     detail: error,
      //     life: 3000,
      //   });
      //  setError(true);
    }
  };

  ///////////

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditRow("");
    setSubmitted(false);
    setProductDialog(false);
  };
  const handleAddOneInGrroup = () => {
    setAddNumberInGroup(true);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setEditRow(product);
    setProductDialog(true);
  };

  const [rowDelete, setRowDelete] = useState("");

  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap  fs-5 gap-3">
        <button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
          className="btn btn-danger rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Delete")}
          <i class="uil uil-shopping-basket mx-2"></i>
        </button>
        <button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Create Group")}
          <i class="uil uil-plus mx-2"></i>
        </button>{" "}
        <button
          label="Export Selected to Excel"
          icon="pi pi-file-excel"
          // onClick={exportToExcel}
          className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
        >
          {t("Export")}
          <i class="uil uil-import mx-2"></i>
        </button>{" "}
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
      <span className="p-input-icon-left col-12 col-md-6">
        <i className="pi pi-search" />
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="search"
            onInput={(e) => {
              setGlobalFilter(e.target.value);
              getDataSearch(e.target.value);
            }}
            placeholder="Search..."
            class="form-control"
          />
          <label for="textInputExample">{t("Search...")}</label>
        </div>
      </span>
    </div>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <Link to={`/smspro-groups_grid/${rowData._id}`}>
        {rowData.firstName + " " + rowData.lastName}
      </Link>
    );
  };

  const handleToggleChange = (rowData, value) => {
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData.id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <InputSwitch
        checked={checkedRows[rowData.id] || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <i
            class="uil uil-edit fs-17 text-blue cursor-pointer"
            icon="pi pi-pencil mr-2"
            onClick={() => {
              editProduct(rowData);
            }}
          ></i>

          <div className="d-flex gap-2">
            <Button
              className=" bg-danger py-1 px-2"
              onClick={() => confirmDeleteProduct(rowData)}
            >
              <i className="ri-delete-bin-2-line bg-danger"></i>
            </Button>
          </div>

          <i class="uil uil-copy  fs-17 text-green cursor-pointer"></i>

          <i
            class="uil uil-plus-circle fs-17 text-green cursor-pointer"
            onClick={handleAddOneInGrroup}
          ></i>
        </div>
      </React.Fragment>
    );
  };
  const actionEdit = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-gradient	 py-1 px-2"
            onClick={() => editProduct(rowData)}
          >
            <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}

        <DataTable
          width={"100%"}
          ref={dt}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          header={header}
          selectionMode={"checkbox"}
          totalRecords={20}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          {/* <Column
            className="fs-13 "
            field="id"
            header="Id"
            sortable
            style={{ minWidth: "2rem" }}
          ></Column> */}

          <Column
            className="fs-13"
            body={nameBodyTemplate}
            header="Name"
            sortable
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-13"
            header="phone Number"
            field="phoneNumber"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-13"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    {rowData.email}
                  </div>
                </React.Fragment>
              );
            }}
            header="Last Import Date"
            sortable
            style={{ minWidth: "7rem" }}
          ></Column>

          <Column
            className="fs-13"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
          ></Column>
          <Column
            className="fs-13"
            header="Edit"
            body={actionEdit}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />

      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Edit ") + editRow.firstName}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={() => handleEdit()}
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <form>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              {t("First Name")}
            </label>
            <InputText
              id="name"
              onChange={(e) => setFirstName(e.target.value)}
              required
              defaultValue={editRow?.firstName}
              autoFocus
            />
          </div>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              {t("Last Name")}
            </label>
            <InputText
              id="name"
              onChange={(e) => setLastName(e.target.value)}
              required
              defaultValue={editRow?.lastName}
            />
          </div>
          <div className="field">
            <label htmlFor="email" className="font-bold">
              {t("Email")}
            </label>
            <InputText
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              defaultValue={editRow?.email}
            />
          </div>
          <div className="field">
            <label htmlFor="phoneNumber" className="font-bold">
              {t("Phone Number")}
            </label>
            <InputText
              id="phoneNumber"
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              defaultValue={editRow?.phoneNumber}
            />
          </div>
        </form>
      </Dialog>
      {/* <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow.name ? t("Edit Group Name") : t("Group Name")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => (editRow.name ? handleEdit() : sendData())}
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            onChange={(e) => setName(e.target.value)}
            required
            //   autoFocus
            defaultValue={name}
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>
      </Dialog> */}

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete ")}
              <b>{rowDelete?.group?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete the selected products?")}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
