import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { useSelector } from "react-redux";
import Moment from "react-moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Spinner } from "react-bootstrap";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
export default function RechargeArchieveTable() {
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  useEffect(() => {
    setProducts();
  }, []);

  const expandAll = () => {
    let _expandedRows = {};

    data.forEach((p) => (_expandedRows[`${p._id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const [searchText, setSearchText] = useState("");

  const allowExpansion = (rowData) => {
    return rowData;
  };
  useEffect(() => {
    setProducts();
  }, [searchText]);
  const [t, i18n] = useTranslation();
  const [totalRecords, settotalRecords] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const [startDate, setStarttDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const getDataSearch = async (page, value, first, last, userEmail) => {
    setLoading(true);
    if (!value) {
      value = "";
    }
    // if (!startDate) {
    //   setStarttDate("");
    // }
    console.log(
      `https://togethersms.com/payment/find-all-payments` +
        "?page=" +
        page +
        "&searchField=" +
        value +
        `&startDate=${first}&endDate=${last}`
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/payment/find-all-payments` +
          "?page=" +
          page +
          "&searchField=" +
          value +
          `&startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res);
      setLoading(false);
      if (res) {
        setData(res.data);
        settotalRecords(res.total);
        console.log(res.total);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(
      currentPage,
      globalFilter,
      startDate,
      lastDate,
      globalFilterUsername
    );
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const rowExpansionTemplate = (data) => {
    // const [arrayToDownload, setarrayToDownload] = useState("");
    const arrayToDownload = data.totalPhoneNumbers;
    const downloadArrayAsFile = () => {
      const textToSave = arrayToDownload.join("\n"); // Convert array to a string

      const blob = new Blob([textToSave], { type: "text/plain" });
      saveAs(blob, "array.txt");
    };

    return <div className="p-2"></div>;
  };
  const [loading, setLoading] = useState();
  const exportToExcel = () => {
    console.log("startDate", startDate);
    console.log("lastDate", lastDate);
    fetch(
      `https://togethersms.com/payment/export-all-payments?startDate=${startDate}&endDate=${lastDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // useEffect(() => {

  // }, [user.token, data]);
  const [selectedDate, setSelectedDate] = useState(null);

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={expandAll}
      >
        {t("Expand All")}
      </div>
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={collapseAll}
      >
        {t("Collapse All")}
      </div>
    </div>
  );
  const [globalFilterUsername, setGlobalFilterUsername] = useState("");

  const [editRow, setEditRow] = useState("");

  const editProduct = (product) => {
    setEditRow(product);

    setispaid(product?.isPaid == "true" ? "true" : "false");
    setpaidStatus(product?.paidStatus == "true" ? "true" : "false");
    // setProductDialog(true);
  };
  const handleEdit = async () => {
    console.log(editRow._id);
    console.log(user.token);
    // hideDialog();
    setLoading(true);
    setEditRow("");

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-by-admin/${editRow.user._id}/${editRow._id}`,
        formData,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const actionEdit = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2">
          <Button
            className=" bg-gradient	 py-1 px-2"
            onClick={() => editProduct(rowData)}
          >
            <i className=" ri-edit-box-line text-info-emphasis cursor-pointe"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const [isPaid, setispaid] = useState(
    editRow?.isPaid == "true" ? "true" : "false"
  );
  const [paidStatus, setpaidStatus] = useState("");
  const [paymentType, setpaymentType] = useState("");
  const [paidDate, setpaidDate] = useState("");

  const handleAccept = async () => {
    console.log(editRow);
    console.log(user.token);
    // hideDialog();
    setLoading(true);
    setEditRow("");

    const data = {
      isPaid,
      paidStatus,
    };
    if (editRow?.points) {
      data.paymentType = "Points";
    }
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/payment/update/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(
          1,
          globalFilter,
          startDate,
          lastDate,
          globalFilterUsername
        );
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const handleDateChange = async (selectedDates) => {
    setSelectedDate(selectedDates[0]);

    setStarttDate(selectedDates[0]);
    setLastDate(selectedDates[1]);

    const formattedDate = selectedDates[0].toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    console.log(formattedDate);
  };

  const [typeSelect, setTypeSelect] = useState();

  //delete
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  const [rowDelete, setRowDelete] = useState("");
  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <div className="d-flex gap-2 h-100 ">
            <Button
              className=" bg-danger py-1 px-2 h-100 py-2"
              onClick={() => confirmDeleteProduct(rowData)}
            >
              <i className="h-100 uil fs-17 bx bx-block bg-danger"></i>
            </Button>
          </div>

          <i class="uil uil-copy  fs-17 text-green cursor-pointer"></i>

          <i
            class="uil uil-plus-circle fs-17 text-green cursor-pointer"
            // onClick={handleAddOneInGrroup}
          ></i>
        </div>
      </React.Fragment>
    );
  };

  const handleDelete = async () => {
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);

    setRowDelete("");
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/user/update-user-blacklist/${rowDelete._id}`,

        config,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //   toast.current.show({
      //     severity: "error",
      //     summary: "Error",
      //     detail: error,
      //     life: 3000,
      //   });
      //  setError(true);
    }
  };
  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const [typeSearch, setTypeSearch] = useState(false);
  const exportSelectedPayments = () => {
    // Check if any payments are selected
    if (selectedProducts.length === 0) {
      alert("Please select at least one payment to export.");
      return;
    }

    // Extract IDs of selected payments
    const selectedPaymentIds = selectedProducts.map((payment) => payment._id);
    console.log(selectedPaymentIds);
    // Send a request to the API to export selected payments
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        withCredentials: false,
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios
      .get(
        "https://togethersms.com/payment/export-selected-payments",
        {
          paymentsId: selectedPaymentIds,
        },
        config
      )
      .then((response) => {
        // Handle success
        const exportedData = response.data;

        // Convert data to a JSON string
        const jsonData = JSON.stringify(exportedData);

        // Create a Blob object containing the JSON data
        const blob = new Blob([jsonData], { type: "application/json" });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = "exported_payments.json";

        // Programmatically trigger the click event on the anchor element
        document.body.appendChild(a);
        a.click();

        // Cleanup: remove the temporary anchor and revoke the Blob URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // Handle error
        console.error("Export failed:", error);
      });
  };
  const [userName, setUser] = useState("");

  return (
    <div className="card">
      <Toast ref={toast} />
      {/* <div className="d-flex" style={{border:"3px solid black",width:"50%"}} */}
      {/* <div className="p-2">
        <div className="d-flex gap-3 text-center justify-content-center">
          <div className=" d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary  mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={() => {
                setTypeSearch(!typeSearch);
              }}
            >
              Search
            </div>
          </div>
          <div className=" d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary  mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={exportToExcel}
            >
              {t("Export all the data")}
            </div>
          </div>
        </div>
        {typeSearch ? (
          <>
            <div className="d-flex gap-2 border-0 ">
              <div className="d-flex gap-1 flex-wrap">
                <div>
                  <label>Start Date : </label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select start date"
                  />
                </div>
                <div>
                  <label>End Date : </label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select end date"
                  />
                </div>
                <div
                  className="cursor-pointer input-group-text py-2 bg-primary border-primary text-white"
                  onClick={() => {
                    getDataSearch(1, globalFilter, startDate, endDate);
                  }}
                >
                  Search
                </div>
                <div
                  className="cursor-pointer input-group-text py-2 bg-primary border-primary text-white"
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                    getDataSearch(1, globalFilter, "", "");
                  }}
                >
                  Clear
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-floating  col-md-6 col-12  mt-3">
                <input
                  id="textInputExample"
                  type="text"
                  className="form-control"
                  onInput={(e) => {
                    setGlobalFilter(e.target.value);
                    setCurrentPage(1);

                    getDataSearch(1, e.target.value, startDate, endDate);
                  }}
                  placeholder="Text Input"
                />
                <label htmlFor="textInputExample">{t("Search")}</label>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div> */}
      <div className="p-2">
        <div className="d-flex align-items-center gap-2 border-0 ">
          <Flatpickr
            className="form-control  border-0 dash-filter-picker shadow "
            onChange={handleDateChange}
            options={{
              mode: "range",
              dateFormat: "d M, Y",
              defaultDate: [new Date()],
            }}
          />
          <div
            className="cursor-pointer input-group-text bg-primary border-primary text-white"
            onClick={() => {
              getDataSearch(
                1,
                globalFilter,
                startDate,
                lastDate,
                globalFilterUsername
              );
            }}
          >
            Search
          </div>
          <div
            className="cursor-pointer input-group-text bg-primary border-primary text-white"
            onClick={() => {
              setStarttDate("");
              setLastDate("");
              getDataSearch(1, globalFilter, "", "", globalFilterUsername);
            }}
          >
            Clear
            {/* <BsFillCalendar2MonthFill /> */}
          </div>
        </div>
        <div className="row">
          {typeSelect == "userName" ? (
            <div class="form-floating  col-md-6 col-12  mt-3">
              <input
                id="textInputExample"
                type="text"
                class="form-control"
                onInput={(e) => {
                  setGlobalFilterUsername(e.target.value);
                  setCurrentPage(1);

                  getDataSearch(1, "", startDate, lastDate, e.target.value);
                }}
                placeholder="Text Input"
              />
              <label for="textInputExample">{t("Search User name")}</label>
            </div>
          ) : typeSelect == "senderName" ? (
            <div class="form-floating  col-md-6 col-12  mt-3">
              <input
                id="textInputExample"
                type="text"
                class="form-control"
                onInput={(e) => {
                  setGlobalFilter(e.target.value);
                  setCurrentPage(1);

                  getDataSearch(1, e.target.value, startDate, lastDate, "");
                }}
                placeholder="Text Input"
              />
              <label for="textInputExample">
                {t("Search by sender names")}
              </label>
            </div>
          ) : (
            <div class="form-floating  col-md-6 col-12  mt-3">
              <input
                id="textInputExample"
                type="text"
                class="form-control"
                onInput={(e) => {
                  setGlobalFilter(e.target.value);
                  setCurrentPage(1);

                  getDataSearch(1, e.target.value, startDate, lastDate, "");
                }}
                placeholder="Text Input"
              />
              <label for="textInputExample">{t("Search by Order_id")}</label>
            </div>
          )}
          <div class="form-floating  col-md-6 col-12  mt-3">
            <div class="form-floating ">
              <select
                className="form-select"
                aria-label="Default select example"
                defaultValue={"1"}
                onChange={(e) => setTypeSelect(e.target.value)}
              >
                <option disabled value={"1"}>
                  {" "}
                  {t("Select type Search")}
                </option>
                <option value={"Order_id"}>search by Order_id</option>
                <option value={"senderName"}>search by senderName</option>
                <option value={"userName"}>search by userName</option>
              </select>

              {/* {submitted && !product.phone && (
                <small className="p-error">Message Price is required.</small>
              )} */}
            </div>
          </div>

          <div className="col-lg-6 col-12 d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary rounded-pill d-flex align-items-center mb-0 mb-2"
              onClick={exportSelectedPayments}
            >
              {t("Export the selected")}
            </div>
            <div
              className="btn btn-primary d-flex align-items-center rounded-pill mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={exportToExcel}
            >
              {t("Export all the data")}
            </div>
          </div>
        </div>
      </div>
      <DataTable
        style={{ minHeight: "100vh", border: "1px solid #ccc" }}
        lazy
        loading={loading}
        width={"100%"}
        value={data}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="_id"
        // header={header}
        rows={5}
        totalRecords={totalRecords}
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
      >
        {/* <Column selectionMode="multiple" headerStyle={{ width: ".5rem" }} /> */}
        <Column
          header="Order_id"
          style={{ width: "8rem" }}
          body={(rowData) => {
            return <div>{rowData.order}</div>;
          }}
          className="fs-14 "
        />
        <Column
          className="fs-13"
          // body={nameBodyTemplate}
          body={(rowData) => {
            return (
              <React.Fragment>
                <div
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  style={{ cursor: "pointer" }}
                  onClick={() => setUser(rowData.user)}
                >
                  {rowData.user.firstName}
                </div>
              </React.Fragment>
            );
          }}
          header="Name"
          style={{ minWidth: "5rem" }}
        ></Column>
        <Column
          body={(rowData) => {
            return (
              <>
                {rowData.isPaid == "true" ? (
                  <div className="text-success fs-4 fw-bolder"> true </div>
                ) : (
                  <div className="text-danger fs-4 fw-bolder"> false </div>
                )}
              </>
            );
          }}
          header="isPaid"
          className="fs-14 "
        />
        <Column
          body={(rowData) => {
            return (
              <>
                {rowData.paidStatus == "true" ? (
                  <div className="text-success fs-4 fw-bolder"> true </div>
                ) : (
                  <div className="text-danger fs-4 fw-bolder"> false </div>
                )}
              </>
            );
          }}
          header="Status"
          className="fs-14 "
        />

        <Column
          header="Cost"
          style={{ width: "8rem" }}
          body={(rowData) => {
            return (
              <>
                {rowData.isPaid ? (
                  <div className="fs-4 fw-bolder text-success d-flex">
                    {rowData.paidAmount} <span>EGP</span>
                  </div>
                ) : (
                  <div className="fs-4 text-danger fw-bolder d-flex">
                    {rowData.paidAmount} <span>EGP</span>
                  </div>
                )}
              </>
            );
          }}
          className="fs-14 "
        />
        <Column
          header="points"
          style={{ width: "8rem" }}
          body={(rowData) => {
            return (
              <div>{rowData.points ? rowData.points : rowData?.senderName}</div>
            );
          }}
          className="fs-14 "
        />
        <Column
          field="name"
          header="paymentMethod"
          body={(rowData) => {
            return <div>{rowData?.paymentMethod}</div>;
          }}
          className="fs-13 "
        />
        <Column
          header="Created At"
          body={(rowData) => {
            return (
              <React.Fragment>
                <div className="d-flex align-items-center gap-2">
                  <Moment
                    format="YYYY/MM/DD"
                    date={rowData.createdAt}
                    style={{ marginTop: "-10px" }}
                  />{" "}
                </div>
              </React.Fragment>
            );
          }}
          className="fs-14 "
        />
        <Column
          header="Phone"
          style={{ width: "8rem" }}
          body={(rowData) => {
            return <div>{rowData.user?.phoneNumber}</div>;
          }}
          className="fs-14 "
        />

        <Column
          className="fs-13"
          header="Edit"
          body={actionEdit}
          exportable={false}
          // style={{ minWidth: "8rem" }}
        ></Column>
      </DataTable>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalRecords} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />
      <Dialog
        visible={editRow}
        style={{ width: "45rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Edit Recharge")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={() => setEditRow("")}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) => handleAccept()}
            />
          </React.Fragment>
        }
        onHide={() => setEditRow("")}
      >
        <div className="d-flex align-items-center mb-4 row">
          <div class="form-select-wrapper  col-2 d-md-block ">
            <label className="text-orange ">{t("Is Paid")}</label>
            {isPaid}
          </div>
          <div class="form-floating  col-md-10  col-12">
            <div class="form-floating ">
              <div className="form-select-wrapper">
                <select
                  className="form-select"
                  required
                  aria-label="Default select example"
                  onChange={(e) => setispaid(e.target.value)}
                  value={isPaid}
                >
                  <option> {t("Select Type")}</option>
                  <option value={"true"}>true </option>
                  <option value={"false"}>false </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mb-4 row">
          <div class="form-select-wrapper  col-2 d-md-block ">
            <label className="text-orange ">{t("Paid Status")}</label>
          </div>
          <div class="form-floating  col-md-10  col-12">
            <div class="form-floating ">
              <div className="form-select-wrapper">
                <select
                  className="form-select"
                  required
                  aria-label="Default select example"
                  onChange={(e) => setpaidStatus(e.target.value)}
                  value={paidStatus}
                >
                  <option> {t("Select Type")}</option>
                  <option value={"true"}>true </option>
                  <option value={"false"}>false </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {rowDelete && (
            <span>
              {t("Are you sure you want to delete ")}
              <b>{rowDelete?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>{" "}
      <Dialog
        visible={userName}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Information"
        modal
        // footer={deleteProductsDialogFooter}
        onHide={() => setUser("")}
      >
        <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
          <div class="form-select-wrapper  d-md-block d-none">
            <label className="text-orange ">{t("Name")}</label>
          </div>
          <div class="form-floating  ">{userName.firstName}</div>
        </div>
        <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
          <div class="form-select-wrapper  d-md-block d-none">
            <label className="text-orange ">{t("Email")}</label>
          </div>
          <div class="form-floating  ">{userName.email}</div>
        </div>
        <div className="d-flex align-items-center  justify-content-between w-100 mb-4">
          <div class="form-select-wrapper  d-md-block d-none">
            <label className="text-orange ">{t("Phone")}</label>
          </div>
          <div class="form-floating  ">{userName.phoneNumber}</div>
        </div>
      </Dialog>
    </div>
  );
}
